import { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";

// @core
import { useGlobalContext } from "@core/context";

// mui
import { useTheme, Theme } from "@mui/material/styles";

// libs
// https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/npm/
import { Crisp, ChatboxPosition, ChatboxColors } from "crisp-sdk-web";

export const CrispConfig = {
  colors: [
    'default',
    'amber',
    'black',
    'blue',
    'blue_grey',
    'light_blue',
    'brown',
    'cyan',
    'green',
    'light_green',
    'grey',
    'indigo',
    'orange',
    'deep_orange',
    'pink',
    'purple',
    'deep_purple',
    'red',
    'teal',
  ],
  positions: [
    'right',
    'left',
  ]
}

interface Props {
  apiKey: string
  welcomeMessage: string
  position: 'left' | 'right'
  color: 'default' | 'amber' | 'black' | 'blue' | 'blue_grey' | 'light_blue' | 'brown' | 'cyan' | 'green' | 'light_green' | 'grey' | 'indigo' | 'orange' | 'deep_orange' | 'pink' | 'purple' | 'deep_purple' | 'red' | 'teal'
}

const localKey = 'crisp-client-data'

export default function CrispChat(props: Props) {

  // refs
  const welcomeTimerRef = useRef<NodeJS.Timeout>()

  // context
  const { state: { userInfo } } = useGlobalContext()

  // hooks
  const router = useRouter()
  const theme: Theme = useTheme()

  // states
  const [inited, setInited] = useState(false)
  const [prevLocale, setPrevLocale] = useState(router.locale);

  useEffect(() => {
    if (Crisp.isCrispInjected()) {
      Crisp.chat.show();
    }
  }, [router.pathname])


  // useEffect(() => {
  //   if (prevLocale === router.locale) return
  //   localStorage.removeItem(localKey)
  //   setPrevLocale(router.locale)
  //   if (Crisp.isCrispInjected()) {
  //     welcomeMessage()
  //   }
  // }, [router.locale])


  const loadChat = () => {

    if (!props.apiKey) return

    Crisp.configure(props.apiKey, {
      autoload: true,
      locale: router.locale,
      lockMaximized: false,
      lockFullview: false

      // clientUrl?: string;
      // tokenId?: string;
      // sessionMerge?: boolean;
      // cookieDomain?: string;
      // cookieExpire?: number;
      // safeMode?: boolean;
    });

    Crisp.setZIndex(theme.zIndex.drawer - 1);

    // Crisp.message.send("audio", {
    //   duration: 40,
    //   url: "https://storage.crisp.chat/users/upload/operator/aa0b64dd-9fb4-4db9-80d6-5a49eb84087b/d70935e1-c79e-4199-9568-944541657b78.webm",
    //   type: "audio/webm"
    // });

    Crisp.session.onLoaded((sessionId: string) => {
      setInited(true)
      welcomeMessage()
    });

    // Crisp.message.onMessageReceived((data: string) => {
    //   alert(JSON.stringify(data, null, 2))
    // })


    // const count = Crisp.chat.unreadCount();
    Crisp.setPosition(props.position as ChatboxPosition);
    Crisp.setColorTheme(props.color as ChatboxColors);

    // Crisp.setHideOnMobile(true);
    // Crisp.setAvailabilityTooltip(false);
    // Crisp.muteSound(true);
    // Crisp.setVacationMode(true);
    // Crisp.toggleOperatorCount(true);
    // Crisp.setSafeMode(true);
    // var count = Crisp.chat.unreadCount();
    // var isOpened = Crisp.chat.isChatOpened();
    // var isVisibleOpened = Crisp.chat.isVisible();
    // Crisp.chat.onChatInitiated(() => {
    //   // Executed once the chat was initiated from the user
    // })
    // Crisp.chat.onChatOpened(() => {
    //   // Executed once the chat was opened
    // })
    // Crisp.chat.onChatClosed(() => {
    //   // Executed once the chat was closed
    // })

  }

  const welcomeMessage = () => {
    if (!props.welcomeMessage) return

    const crispChatKey = localStorage.getItem(localKey)
    if (crispChatKey && crispChatKey === props.apiKey) return

    clearTimeout(welcomeTimerRef.current)
    welcomeTimerRef.current = setTimeout(() => {
      Crisp.message.showText(props.welcomeMessage);
      localStorage.setItem(localKey, props.apiKey)
    }, 1000 * 7)
  }

  useEffect(() => {
    loadChat()

    return () => {
      try {
        Crisp.chat.hide();
      } catch (error) { }

      setInited(false)
      clearTimeout(welcomeTimerRef.current)
    }
  }, [])


  // useEffect(() => {

  // }, [theme.palette.primary.main])

  useEffect(() => {
    if (!userInfo) return;

    const first_name = userInfo.first_name
    const email = userInfo.email
    const isEmail = email && email.includes('@')

    try {

      if (isEmail) {
        Crisp.user.setEmail(email);
      }

      if (first_name) {
        Crisp.user.setNickname(userInfo.first_name);
      }

      // Crisp.user.setAvatar("https://pbs.twimg.com/profile_images/834424630630817795/TfyS4uXb_400x400.jpg");

    } catch (error) {
      console.error('Crisp.user error', error)
    }


  }, [userInfo])

  return <></>
}