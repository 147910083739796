
// @configs
import routes from "@configs/routes";

// types
import type { SolutionItem } from "./types";

export type TechnologySlug = 'python' | 'reactjs' | 'react-native' | 'vuejs' | 'nextjs' | 'django' | 'nodejs' | 'expressjs' | 'mongodb' | 'postgresql' | 'mui' | 'chat-gpt' | 'electron' | 'aws' | 'google-cloud' | 'azure' | 'typescript' | 'vercel' | 'unrealengine' | 'pixel-streaming' | 'metaeditor'

export interface TechnologyItem extends Omit<SolutionItem, 'slug'> {
  slug: TechnologySlug;
  multiColor: boolean;
}

const makeHref = (slug: string) => routes.TECHNOLOGIES.DETAILS.replace('[slug]', slug);

export const technologiesList: TechnologyItem[] = [
  {
    title: 'MetaEditor',
    icon: 'uim:box',
    slug: 'metaeditor',
    multiColor: true,
  },
  {
    title: 'Pixel Streaming',
    icon: 'iconamoon:3d-fill',
    slug: 'pixel-streaming',
    multiColor: false,
  },
  {
    title: 'Unreal Engine',
    icon: 'logos:unrealengine',
    slug: 'unrealengine',
    multiColor: false,
  },
  {
    title: 'Vercel',
    icon: 'logos:vercel',
    slug: 'vercel',
    multiColor: false,
  },
  {
    title: 'Python',
    icon: 'logos:python',
    slug: 'python',
    multiColor: true,
  },
  {
    title: 'ReactJS',
    icon: 'logos:react',
    slug: 'reactjs',
    multiColor: true,
  },
  {
    title: 'React Native',
    icon: 'logos:react',
    slug: 'react-native',
    multiColor: true,
  },
  {
    title: 'Vue.js',
    icon: 'logos:vue',
    slug: 'vuejs',
    multiColor: true,
  },
  {
    title: 'Next.js',
    icon: 'logos:nextjs',
    slug: 'nextjs',
    multiColor: false,
  },
  {
    title: 'TypeScript',
    icon: 'logos:typescript-icon',
    slug: 'typescript',
    multiColor: true,
  },
  {
    title: 'Django',
    icon: 'logos:django-icon',
    slug: 'django',
    multiColor: false,
  },
  {
    title: 'Node.js',
    icon: 'logos:nodejs-icon',
    slug: 'nodejs',
    multiColor: true,
  },
  {
    title: 'Express.js',
    icon: 'logos:express',
    slug: 'expressjs',
    multiColor: false,
  },
  {
    title: 'MongoDB',
    icon: 'logos:mongodb',
    slug: 'mongodb',
    multiColor: true,
  },
  {
    title: 'PostgreSQL',
    icon: 'logos:postgresql',
    slug: 'postgresql',
    multiColor: true,
  },
  {
    title: 'Material UI',
    icon: 'logos:material-ui',
    slug: 'mui',
    multiColor: true,
  },
  {
    title: 'Chat GPT',
    icon: 'logos:openai-icon',
    slug: 'chat-gpt',
    multiColor: false,
  },
  {
    title: 'Electron',
    icon: 'logos:electron',
    slug: 'electron',
    multiColor: false,
  },
  {
    title: 'AWS',
    icon: 'logos:aws',
    slug: 'aws',
    multiColor: true,
  },
  {
    title: 'Google Cloud',
    icon: 'logos:google-cloud',
    slug: 'google-cloud',
    multiColor: true,
  },
  {
    title: 'Azure',
    icon: 'logos:azure-icon',
    slug: 'azure',
    multiColor: false,
  }
].map(item => {
  return {
    ...item,
    slug: item.slug,
    href: makeHref(item.slug),
    multiColor: item.multiColor,
  }
}) as TechnologyItem[];