
interface Project {
  name: string
  static: {
    favicon: string
    svgIcon: string
    ogImage: string
    noThumbnail: string
  }
  gtmId: string
  crispChatId: string
  contacts: {
    name: string
    email: string
    phone: string
  }
  settings: {
    timeZoneGmtOffset: number
  }
  assets: {
    mainVideo: {
      mobile: {
        preview: string
        video: string
        videoSilent: string
      }
      desktop: {
        preview: string
        video: string
        videoSilent: string
      }
    }
    logotypes: { [key: string]: any };
    logotypesCircle: { [key: string]: any };
    videos: {
      [key: string]: string
    }
    images: {
      [key: string]: string
    }
    team: {
      [key: string]: string
    }
    portfolio: {
      [key: string]: string
    }
  }
  links: {
    [key: string]: string
  }
}

const project: Project = {
  name: 'Unrealos.',
  static: {
    favicon: '/static/favicons/android-chrome-384x384.png',
    svgIcon: '/static/logo.svg',
    ogImage: '/static/og-image.jpg',
    noThumbnail: '/static/no-thumbnail.png',
  },
  gtmId: 'G-TM6DHFCKF1',
  crispChatId: '2b3865f3-3b94-4295-a184-8fc8d64b36ea',
  contacts: {
    name: "Unreal Online Solutions S.L.",
    email: "info@unrealos.com",
    phone: "+34 (649) 797 651",
  },
  settings: {
    timeZoneGmtOffset: 8,
  },
  assets: {
    mainVideo: {
      mobile: {
        preview: '/static/main-video/preview-mobile.jpg',
        video: '/static/main-video/mobile.mp4',
        videoSilent: '/static/main-video/mobile-silent.mp4',
      },
      desktop: {
        preview: '/static/main-video/preview-desktop.jpg',
        video: '/static/main-video/desktop.mp4',
        videoSilent: '/static/main-video/desktop-silent.mp4',
      },
    },
    logotypes: {
      epicGames: '/static/logotypes/epic-games.svg',
      unrealEngine: '/static/logotypes/unreal-engine.svg',
      aws: '/static/logotypes/aws.svg',
      metaeditor: '/static/logotypes/metaeditor.svg',
      telesite: '/static/logotypes/telesite.svg',
      lv80: '/static/logotypes/80lv.svg',
      coinTelegraph: '/static/logotypes/coin-telegraph.svg',
      forbes: '/static/logotypes/forbes.svg',
      vc: '/static/logotypes/vc.svg',
      kokodi: '/static/logotypes/kokodi.svg',
    },
    logotypesCircle: {
      unrealEngine: '/static/logotypes-circle/unreal-engine.svg',
      pixelStreaming: '/static/logotypes-circle/pixel-streaming.svg',
    },
    videos: {
      toronto: '/static_tmp/videos/toronto.mp4',
      telesite: '/static_tmp/videos/telesite-builder.mp4',
      metatokens: '/static_tmp/videos/metatokens.mp4',
      metaeditor: '/static_tmp/videos/metaeditor.mp4',
      wixi: '/static_tmp/videos/wixi.mp4?v=2',
    },
    images: {
      forbes: '/static/images/forbes.jpg',
    },
    team: {
      v1: '/static/team/v1.jpeg',
      v2: '/static/team/v2.jpeg',
      v3: '/static/team/v3.jpeg',
      v4: '/static/team/v4.jpeg',
    },
    portfolio: {
      macbookSvg: '/static/svgs/macbook.svg',
      gradientWithGridSvg: '/static/svgs/gradient-with-grid.svg',
      telesiteLogoSvg: '/static/svgs/telesite-logo.svg',
    }
  },
  links: {
    forbesYoutube: 'https://youtu.be/5bnQpaYiQXc?si=AESH6xkY_uVO2DGi&t=1389',
  }
}

export default project