
// theme
import { ThemeProvider } from '@mui/material/styles';
import getTheme from "src/@core/theme";

// mui
import { useTheme } from '@mui/material/styles'


interface Props {
  children: React.ReactNode
  mode: 'inversed' | 'light' | 'dark' | 'auto' | undefined
}

const ThemeProviderMode = ({ children, mode }: Props) => {

  // hooks
  const theme = useTheme()

  let newMode = theme.palette.mode

  if (mode === 'dark' || mode === 'light') {
    newMode = mode

  } else if (mode === 'inversed') {
    newMode = newMode === 'dark' ? 'light' : 'dark'
  }

  const newTheme = getTheme(newMode)

  return (
    <ThemeProvider theme={newTheme}>
      {children}
    </ThemeProvider>
  )
}

export default ThemeProviderMode
