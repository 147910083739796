// ** MUI Imports
import { Theme } from '@mui/material/styles'

// ** Theme Config Imports
import media from '../utils/media';

const input = (theme: Theme) => {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          [media.max.sm]: {
            fontSize: '0.975rem',
            '&[data-shrink="false"]': {
              transform: 'translate(14px, 13px) scale(1)',
            },
          },
          color: theme.palette.text.secondary
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: `1px solid rgba(${theme.palette.primary.main}, 0.22)`
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid rgba(${theme.palette.primary.main}, 0.32)`
          },
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid'
          },
        },
      }
    },
    MuiInputBase: {
      // defaultProps: {
      //   size: 'medium',
      // },
      styleOverrides: {
        root: {
          [media.max.sm]: {
            fontSize: '0.975rem',
          },
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
            WebkitTextFillColor: theme.palette.text.primary,
            backgroundColor: 'transparent',
          },

          [media.max.sm]: {
            fontSize: '0.975rem',
            height: '0.777em',
            '& + .MuiInputAdornment-root .MuiTypography-root': {
              fontSize: '0.875rem',
            },
          }
        },
        inputSizeSmall: {
          fontSize: '0.975rem',
          height: '1.777em',
          minHeight: '1.777em',
          '& + .MuiInputAdornment-root .MuiTypography-root': {
            fontSize: '0.975rem',
          },
        },
        // sizeSmall: {
        //   root: {
        //   },
        //   input: {
        //   },
        // },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: `rgba(${theme.palette.primary.main}, 0.04)`,
          '&:hover:not(.Mui-disabled)': {
            backgroundColor: `rgba(${theme.palette.primary.main}, 0.08)`
          },
          '&:before': {
            borderBottom: `1px solid rgba(${theme.palette.primary.main}, 0.22)`
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid rgba(${theme.palette.primary.main}, 0.32)`
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: `rgba(${theme.palette.primary.main}, 0.32)`
          },
          '&:hover.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `rgba(${theme.palette.primary.main}, 0.22)`
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.disabled
          },
        },
      }
    },
  }
}

export default input
