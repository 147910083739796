import { useEffect } from "react";

// @core
import env from "@core/env"

// mui
import Box from '@mui/material/Box';
import { CircularProgress } from "@mui/material"


export function removePreloader() {
  useEffect(() => {
    const preloader = document.getElementById('document-preloader');
    if (preloader) {
      preloader.remove()
    }
  }, [])
}

export default function DocumentPreloader() {

  return null

  if (env.isDev) return null

  return (
    <Box
      id="document-preloader"

      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: '#0C0A20',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}>
      <CircularProgress size={30} color="inherit" />
    </Box>
  )
}