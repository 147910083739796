// mui
import { useTheme, Theme } from '@mui/material/styles';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';

interface Props extends BoxProps {
  color?: string | 'primary' | 'secondary';
  href?: string;
}

export default function SvgLogo({ color, ...props }: Props) {

  // hooks
  const theme = useTheme<Theme>()

  let svgColor: any = color || theme.palette.text.primary

  switch (color) {
    case 'primary':
      svgColor = theme.palette.primary.main
      break;

    case 'secondary':
      svgColor = theme.palette.text.secondary
      break;

  }

  return (
    <Box
      {...props}
      sx={{
        display: 'inline-block',
        width: 100,
        lineHeight: 0,
        ...props.sx,
      }}
    >
      <svg viewBox="0 0 119 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0499 0.727295H16.5412V13.3186C16.5412 14.6988 16.215 15.9127 15.5625 16.9604C14.9163 18.0081 14.0066 18.8268 12.8335 19.4166C11.6603 20 10.2895 20.2917 8.72108 20.2917C7.14638 20.2917 5.77245 20 4.59927 19.4166C3.42609 18.8268 2.51641 18.0081 1.87022 16.9604C1.22403 15.9127 0.90094 14.6988 0.90094 13.3186V0.727295H4.39224V13.0268C4.39224 13.8299 4.5679 14.5451 4.91923 15.1724C5.27683 15.7998 5.77872 16.2923 6.42491 16.6499C7.0711 17.0012 7.83649 17.1769 8.72108 17.1769C9.60566 17.1769 10.3711 17.0012 11.0172 16.6499C11.6697 16.2923 12.1716 15.7998 12.5229 15.1724C12.8742 14.5451 13.0499 13.8299 13.0499 13.0268V0.727295Z" fill={svgColor} />
        <path d="M23.592 11.5306V20H20.1854V5.54548H23.4415V8.00162H23.6109C23.9434 7.19232 24.4735 6.54926 25.2012 6.07247C25.9353 5.59567 26.8418 5.35727 27.9209 5.35727C28.9184 5.35727 29.7873 5.57057 30.5276 5.99718C31.2741 6.42379 31.8513 7.04175 32.2591 7.85105C32.6732 8.66036 32.8771 9.64219 32.8708 10.7965V20H29.4642V11.3235C29.4642 10.3574 29.2132 9.60141 28.7114 9.0556C28.2157 8.50979 27.5288 8.23688 26.6505 8.23688C26.0545 8.23688 25.5243 8.36863 25.0601 8.63213C24.6021 8.88935 24.2414 9.26263 23.9779 9.75198C23.7206 10.2413 23.592 10.8342 23.592 11.5306Z" fill={svgColor} />
        <path d="M36.3339 20V5.54548H39.637V7.95457H39.7875C40.051 7.12017 40.5027 6.47712 41.1426 6.02541C41.7888 5.56743 42.526 5.33845 43.3541 5.33845C43.5423 5.33845 43.7525 5.34786 43.9846 5.36668C44.223 5.37922 44.4206 5.40118 44.5775 5.43255V8.56625C44.4332 8.51606 44.2042 8.47215 43.8905 8.4345C43.5831 8.39059 43.2851 8.36863 42.9965 8.36863C42.3754 8.36863 41.8171 8.50352 41.3214 8.77328C40.8321 9.03678 40.4463 9.40379 40.1639 9.87431C39.8816 10.3448 39.7405 10.8875 39.7405 11.5023V20H36.3339Z" fill={svgColor} />
        <path d="M52.6446 20.2823C51.1954 20.2823 49.9438 19.9812 48.8898 19.3789C47.8421 18.7704 47.036 17.9109 46.4713 16.8004C45.9067 15.6837 45.6244 14.3694 45.6244 12.8574C45.6244 11.3706 45.9067 10.0657 46.4713 8.94267C47.0422 7.81341 47.839 6.9351 48.8616 6.30773C49.8842 5.67409 51.0856 5.35727 52.4658 5.35727C53.3567 5.35727 54.1974 5.50156 54.9879 5.79015C55.7846 6.07247 56.4873 6.51162 57.0958 7.10762C57.7106 7.70362 58.1937 8.46274 58.545 9.38497C58.8964 10.3009 59.072 11.3925 59.072 12.6598V13.7044H47.2242V11.4082H55.8066C55.8003 10.7558 55.6591 10.1754 55.3831 9.66728C55.1071 9.15284 54.7212 8.74819 54.2256 8.45333C53.7363 8.15846 53.1654 8.01103 52.5129 8.01103C51.8165 8.01103 51.2048 8.18042 50.6778 8.5192C50.1508 8.8517 49.7399 9.29086 49.4451 9.83667C49.1565 10.3762 49.009 10.9691 49.0028 11.6153V13.6197C49.0028 14.4604 49.1565 15.1818 49.4639 15.7841C49.7713 16.3801 50.201 16.8381 50.7531 17.158C51.3052 17.4717 51.9514 17.6286 52.6917 17.6286C53.1873 17.6286 53.6359 17.5596 54.0374 17.4215C54.4389 17.2772 54.7871 17.0671 55.082 16.791C55.3768 16.515 55.5995 16.1731 55.7501 15.7653L58.9309 16.1229C58.7301 16.9636 58.3474 17.6976 57.7828 18.325C57.2244 18.946 56.5092 19.4291 55.6372 19.7742C54.7651 20.1129 53.7676 20.2823 52.6446 20.2823Z" fill={svgColor} />
        <path d="M66.1134 20.2917C65.1975 20.2917 64.3725 20.1286 63.6385 19.8024C62.9107 19.4699 62.3335 18.9806 61.9069 18.3344C61.4866 17.6882 61.2764 16.8914 61.2764 15.9441C61.2764 15.1285 61.427 14.4541 61.7281 13.9208C62.0293 13.3876 62.4402 12.961 62.9609 12.641C63.4816 12.321 64.0682 12.0795 64.7207 11.9164C65.3794 11.747 66.0601 11.6247 66.7628 11.5494C67.6097 11.4616 68.2967 11.3831 68.8237 11.3141C69.3506 11.2388 69.7333 11.1259 69.9717 10.9753C70.2164 10.8185 70.3388 10.577 70.3388 10.2507V10.1943C70.3388 9.48534 70.1286 8.9364 69.7082 8.54743C69.2879 8.15846 68.6825 7.96398 67.892 7.96398C67.0576 7.96398 66.3957 8.14592 65.9064 8.50979C65.4233 8.87366 65.0971 9.30341 64.9277 9.79903L61.747 9.34732C61.9979 8.46901 62.412 7.73499 62.9891 7.14526C63.5663 6.54926 64.2721 6.10383 65.1065 5.80897C65.9409 5.50784 66.8631 5.35727 67.8732 5.35727C68.5696 5.35727 69.2628 5.43882 69.9529 5.60194C70.643 5.76506 71.2735 6.03482 71.8444 6.41124C72.4153 6.78139 72.8733 7.28642 73.2184 7.92634C73.5697 8.56625 73.7454 9.36615 73.7454 10.326V20H70.4705V18.0144H70.3576C70.1505 18.4159 69.8588 18.7923 69.4824 19.1437C69.1122 19.4887 68.6449 19.7679 68.0802 19.9812C67.5219 20.1882 66.8663 20.2917 66.1134 20.2917ZM66.998 17.7886C67.6818 17.7886 68.2747 17.6537 68.7766 17.3839C69.2785 17.1079 69.6643 16.744 69.9341 16.2923C70.2101 15.8406 70.3482 15.3481 70.3482 14.8148V13.1115C70.2415 13.1994 70.0596 13.2809 69.8024 13.3562C69.5514 13.4315 69.2691 13.4974 68.9554 13.5538C68.6417 13.6103 68.3312 13.6605 68.0238 13.7044C67.7164 13.7483 67.4497 13.7859 67.2239 13.8173C66.7157 13.8863 66.2609 13.9993 65.8593 14.1561C65.4578 14.3129 65.141 14.5325 64.9089 14.8148C64.6768 15.0909 64.5607 15.4485 64.5607 15.8876C64.5607 16.515 64.7897 16.9887 65.2477 17.3086C65.7056 17.6286 66.2891 17.7886 66.998 17.7886Z" fill={svgColor} />
        <path d="M80.5774 0.727295V20H77.1708V0.727295H80.5774Z" fill={svgColor} />
        <path d="M90.3855 20.2823C88.9739 20.2823 87.7505 19.9718 86.7154 19.3507C85.6802 18.7296 84.8772 17.8607 84.3063 16.744C83.7417 15.6273 83.4594 14.3223 83.4594 12.8292C83.4594 11.3361 83.7417 10.028 84.3063 8.90503C84.8772 7.78204 85.6802 6.91 86.7154 6.28891C87.7505 5.66781 88.9739 5.35727 90.3855 5.35727C91.7971 5.35727 93.0204 5.66781 94.0556 6.28891C95.0907 6.91 95.8906 7.78204 96.4553 8.90503C97.0262 10.028 97.3116 11.3361 97.3116 12.8292C97.3116 14.3223 97.0262 15.6273 96.4553 16.744C95.8906 17.8607 95.0907 18.7296 94.0556 19.3507C93.0204 19.9718 91.7971 20.2823 90.3855 20.2823ZM90.4043 17.5533C91.1697 17.5533 91.8096 17.3431 92.3241 16.9228C92.8385 16.4962 93.2212 15.9253 93.4721 15.2101C93.7294 14.4949 93.858 13.6981 93.858 12.8198C93.858 11.9352 93.7294 11.1353 93.4721 10.4201C93.2212 9.69865 92.8385 9.12461 92.3241 8.698C91.8096 8.27139 91.1697 8.05808 90.4043 8.05808C89.6201 8.05808 88.9676 8.27139 88.4469 8.698C87.9325 9.12461 87.5467 9.69865 87.2894 10.4201C87.0385 11.1353 86.913 11.9352 86.913 12.8198C86.913 13.6981 87.0385 14.4949 87.2894 15.2101C87.5467 15.9253 87.9325 16.4962 88.4469 16.9228C88.9676 17.3431 89.6201 17.5533 90.4043 17.5533Z" fill={svgColor} />
        <path d="M111.627 9.36614L108.522 9.70492C108.434 9.39124 108.28 9.09638 108.061 8.82034C107.847 8.54429 107.559 8.32158 107.195 8.15219C106.831 7.9828 106.386 7.8981 105.859 7.8981C105.15 7.8981 104.554 8.05181 104.071 8.35922C103.594 8.66663 103.359 9.06501 103.365 9.55436C103.359 9.97469 103.512 10.3166 103.826 10.5801C104.146 10.8436 104.673 11.06 105.407 11.2294L107.873 11.7564C109.24 12.0513 110.257 12.5187 110.922 13.1586C111.593 13.7985 111.932 14.636 111.938 15.6712C111.932 16.5809 111.665 17.3839 111.138 18.0803C110.617 18.7704 109.893 19.3099 108.964 19.6989C108.036 20.0879 106.969 20.2823 105.765 20.2823C103.995 20.2823 102.571 19.9122 101.492 19.1719C100.413 18.4253 99.7701 17.387 99.5631 16.057L102.885 15.7371C103.036 16.3895 103.356 16.882 103.845 17.2145C104.334 17.547 104.971 17.7133 105.755 17.7133C106.565 17.7133 107.214 17.547 107.703 17.2145C108.199 16.882 108.447 16.4711 108.447 15.9817C108.447 15.5677 108.287 15.2258 107.967 14.956C107.653 14.6862 107.164 14.4792 106.499 14.3349L104.033 13.8173C102.647 13.5287 101.621 13.0425 100.956 12.3587C100.291 11.6686 99.9615 10.7965 99.9677 9.74257C99.9615 8.8517 100.203 8.08004 100.692 7.42758C101.188 6.76884 101.875 6.26068 102.753 5.90308C103.638 5.5392 104.657 5.35727 105.812 5.35727C107.506 5.35727 108.839 5.718 109.811 6.43948C110.79 7.16095 111.395 8.1365 111.627 9.36614Z" fill={svgColor} />
        <path d="M116.845 20.2071C116.275 20.2071 115.785 20.0063 115.377 19.6048C114.97 19.2033 114.769 18.7139 114.775 18.1367C114.769 17.5721 114.97 17.089 115.377 16.6875C115.785 16.286 116.275 16.0853 116.845 16.0853C117.398 16.0853 117.878 16.286 118.285 16.6875C118.699 17.089 118.91 17.5721 118.916 18.1367C118.91 18.5194 118.809 18.8676 118.615 19.1813C118.426 19.495 118.176 19.7459 117.862 19.9341C117.554 20.1161 117.216 20.2071 116.845 20.2071Z" fill={svgColor} />
      </svg>
    </Box>
  )
}