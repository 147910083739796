
// @configs
import routes from "@configs/routes";

// mui
import Box from '@mui/material/Box';

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// @components
import { ButtonLink } from '@components/Link'


export default function Company() {

  const items = menuCompany

  return (
    <Box sx={{
      p: 5,
      width: 230,
    }}>
      <Box>

        {items.map((item, index) => {
          return (
            <Box key={index}>
              <ButtonLink
                endIcon={<ArrowForwardIcon />}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                  typography: 'subtitle2',
                }}
                fullWidth
                href={item?.href || '#'}>
                {item.title}
              </ButtonLink>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}


type MenuItem = {
  title: string;
  href: string;
}

const menuCompany: MenuItem[] = [
  {
    title: 'About',
    href: routes.PAGES.ABOUT,
  },
  {
    title: 'Our Team',
    href: routes.PAGES.TEAM,
  },
  {
    title: 'Careers',
    href: routes.PAGES.CAREERS,
  },
  {
    title: 'Contact Us',
    href: routes.PAGES.CONTACTS,
  },
  // {
  //   title: 'Blog',
  //   href: routes.PAGES.BLOG,
  // },
]