import NextLink from "next/link";


// mui
import { Button, ButtonProps, Link as MuiLink, LinkProps } from "@mui/material";

interface PropsButton extends ButtonProps {
  href: string;
}

export const ButtonLink = (props: PropsButton) => {
  return (
    <Button
      {...props}
      LinkComponent={NextLink}
      onClick={(e) => {
        e.stopPropagation()
        props.onClick && props.onClick(e)
      }}
    />
  )
}

interface PropsLink extends LinkProps {
  href: string;
}

export const Link = (props: PropsLink) => {
  return (
    <MuiLink
      {...props}
      component={NextLink}
      onClick={(e) => {
        e.stopPropagation()
        props.onClick && props.onClick(e)
      }}
    />
  )
}