// mui
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, Theme } from "@mui/material/styles";
import type { Breakpoint } from "@mui/system";

type MediaType = Record<Breakpoint, boolean>
interface MediaQueryObject {
	up: MediaType;
	down: MediaType;
	only: MediaType
	mobile: boolean;
	tablet: boolean;
	desktop: boolean;
}

const useMedia = (): MediaQueryObject => {
	const theme = useTheme<Theme>();

	const matches: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

	const media = matches.reduce((acc: MediaQueryObject, breakpoint: Breakpoint) => {
		acc.up[breakpoint] = useMediaQuery(theme.breakpoints.up(breakpoint));
		acc.down[breakpoint] = useMediaQuery(theme.breakpoints.down(breakpoint));
		acc.only[breakpoint] = useMediaQuery(theme.breakpoints.only(breakpoint));
		acc.mobile = useMediaQuery(theme.breakpoints.down('sm'));
		acc.tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
		acc.desktop = useMediaQuery(theme.breakpoints.up('md'));
		return acc;
	}, {
		up: {} as MediaType,
		down: {} as MediaType,
		only: {} as MediaType,
		mobile: false,
		tablet: false,
		desktop: false,
	});

	return media;

	return {
		up: {
			xs: false,
			sm: false,
			md: false,
			lg: true,
			xl: true,
		},
		down: {
			xs: false,
			sm: false,
			md: false,
			lg: true,
			xl: true,
		},
		only: {
			xs: false,
			sm: false,
			md: false,
			lg: true,
			xl: true,
		},
		mobile: false,
		tablet: false,
		desktop: true,
	}
};

// const t = () => {
// 	const media = useMedia();

// 	// Example usage:
// 	const isXsDown = media.down.xs
// 	const isSmUp = media.up.sm
// 	const isTabletOnly = media.only.md
// 	const isMobile = media.mobile;
// 	const isTablet = media.tablet;
// 	const isDesktop = media.desktop;
// };

export default useMedia;
