import Head from 'next/head';
import type { AppProps } from 'next/app';

// @next imports
// import 'typeface-inter';
import '@assets/styles.css'

// @configs
import project from '@configs/project';

import { useEffect } from 'react';
import Router, { useRouter } from 'next/router';

// i18n
import { useTranslation, appWithTranslation } from 'next-i18next'

// @hooks
import useAnalyticsRouter from "@hooks/useAnalyticsRouter"

// theme
import ThemeProvider from '@core/snippets/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '@core/theme/createEmotionCache';

// @components
import NProgress from '@components/NProgress'

// next-auth
import { SessionProvider } from "next-auth/react"
import type { Session } from "next-auth"

// context
import ContextProvider from '@core/context';

// @core layouts
import AppLayout from '@core/layouts/AppLayout';
import ErrorBoundary from '@core/layouts/ErrorBoundary'
import { removePreloader } from '@core/snippets/DocumentPreloader';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

// Use of the <SessionProvider> is mandatory to allow components that call
// `useSession()` anywhere in your application to access the `session` object.

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

// Use of the <SessionProvider> is mandatory to allow components that call
// `useSession()` anywhere in your application to access the `session` object.
const App = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps: { session, ...pageProps },
  ...other
}: MyAppProps & {
  session: Session;
}) => {


  // hooks
  const router = useRouter();
  const { i18n } = useTranslation();

  removePreloader()

  // computed
  const newPageProps = { ...pageProps, router: other.router }

  // Change NEXT_LOCALE in localStorage when router.locale changes
  useEffect(() => {
    if (!router.locale) return

    if (i18n.languages.includes(router.locale)) {
      i18n.changeLanguage(router.locale);
      localStorage.setItem('NEXT_LOCALE', router.locale);
    }

  }, [router.locale]);

  // Redirect to USER_LOCALE if it exists
  useEffect(() => {
    const USER_LOCALE = localStorage.getItem('USER_LOCALE');
    if (USER_LOCALE && router.locale !== USER_LOCALE && i18n.languages.includes(USER_LOCALE)) {
      i18n.changeLanguage(USER_LOCALE);
      router.replace(router.asPath, router.asPath, { locale: USER_LOCALE })
    }
  }, []);

  // Analytics
  useAnalyticsRouter({
    gtmId: project.gtmId,
  });


  return (
    <CacheProvider value={emotionCache}>

      <Head>
        <meta
          name="viewport"
          content={`user-scalable=no, initial-scale=1, minimum-scale=1, maximum-scale=1, width=device-width height=device-height, shrink-to-fit=no, viewport-fit=cover`}
        />

        <title>
          {project.name}
        </title>
      </Head>

      <SessionProvider session={session}>
        <ContextProvider pageProps={newPageProps}>

          <ThemeProvider mode={pageProps.config?.themeMode}>

            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />

            <NProgress />

            <ErrorBoundary>
              <AppLayout config={pageProps.config} >
                <Component {...pageProps} />
              </AppLayout>
            </ErrorBoundary>

          </ThemeProvider>

        </ContextProvider>
      </SessionProvider>

    </CacheProvider>

  );
};


export default appWithTranslation(App);
