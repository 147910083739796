import Head from 'next/head'

// @core
import useTranslate from "@core/i18n/useTranslate";
import { useGlobalContext } from '@core/context';
import env from '@core/env'

// @configs
import project from '@configs/project'

interface Props {
  ns: string;
  title?: string;
  description?: string;
  image?: string | null;
}

const SeoData = ({ ns, ...props }: Props) => {
  // context
  const { pageUrl } = useGlobalContext()

  const { t } = useTranslate('common');

  // computed
  const image = props.image || project.static.ogImage

  const title = (() => {
    if (!props.title) return project.name
    return t(props.title)
  })()

  const description = t(props.description || '')

  const canonicalUrl = pageUrl ? `${env.DOMAIN}${pageUrl}` : false

  return (
    <Head>
      {title ? (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </>
      ) : ''}

      {description ? (
        <meta name="description" content={description} />
      ) : ''}

      <meta property="og:image" content={image} />

      {canonicalUrl ? (
        <link rel="canonical" href={canonicalUrl} />
      ) : ''}


    </Head>
  )
}


export default SeoData