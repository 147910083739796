// ** Type Imports
import { darken, lighten } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'
import { ThemeColors } from '../types'
import { alpha } from '@mui/material/styles'

import {
  // blue,
  // green as MuiSecondary,
} from '@mui/material/colors';

const DefaultPalette = (mode: PaletteMode, colors?: ThemeColors) => {

  // ** Vars
  const whiteColorHex = '#FFF'
  const darkColorHex = '#161616'

  const lightColor = '58, 53, 65'
  const darkColor = '231, 227, 252'

  const mainColor = mode === 'light' ? lightColor : darkColor

  const blue = {
    300: '#6F9BFF',
    500: '#2A5EAC',
    700: '#1B3A6B',
  }

  const MuiPrimary = (() => {
    return {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
      contrastText: whiteColorHex,
      alpha: (opacity: number) => alpha(blue[500], opacity)
    }
  })()

  const MuiSecondary = (() => {
    const c = mode === 'light' ? '0,0,0' : '255,255,255'
    return {
      light: `rgba(${c}, 0.08)`,
      main: `rgba(${c}, 0.12)`,
      dark: `rgba(${c}, 0.14)`,
      contrastText: whiteColorHex,
      alpha: (opacity: number) => alpha(blue[500], opacity)
    }
  })()

  const primaryColor = (() => {
    const c = colors?.primary || MuiPrimary
    return {
      ...c,
      contrastText: whiteColorHex,
      alpha: (opacity: number) => alpha(c.main, opacity)
    }
  })()

  const secondaryColor = (() => {
    const c = colors?.secondary || MuiSecondary
    return {
      ...c,
      contrastText: mode === 'light' ? darkColorHex : whiteColorHex,
      alpha: (opacity: number) => alpha(c.main || '', opacity)
    }
  })()

  const background = {
    paper: mode === 'light' ? '#FFFFFF' : '#222222',
    default: mode === 'light' ? '#F1F1F1' : '#161616',
  }


  // const primaryGradient = () => {
  //   if (themeColor === 'primary') {
  //     return '#C6A7FE'
  //   } else if (themeColor === 'secondary') {
  //     return '#9C9FA4'
  //   } else if (themeColor === 'success') {
  //     return '#93DD5C'
  //   } else if (themeColor === 'error') {
  //     return '#FF8C90'
  //   } else if (themeColor === 'warning') {
  //     return '#FFCF5C'
  //   } else {
  //     return '#6ACDFF'
  //   }
  // }

  // const defaultBgColor = () => {
  //   if (skin === 'bordered' && mode === 'light') {
  //     return '#F5F5F5'
  //   } else if (skin === 'bordered' && mode === 'dark') {
  //     return '#161616'
  //   } else if (mode === 'light') {
  //     return '#F4F5FA'
  //   } else return '#28243D'
  // }

  return {
    customColors: {
      // dark: darkColor,
      // main: mainColor,
      // light: lightColor,
      // primaryGradient: primaryGradient(),
      // bodyBg: mode === 'light' ? '#F4F5FA' : '#28243D', // Same as palette.background.default but doesn't consider bordered skin
      // trackBg: mode === 'light' ? '#F0F2F8' : '#474360',
      // darkBg: skin === 'bordered' ? '#312D4B' : '#28243D',
      // lightBg: skin === 'bordered' ? whiteColor : '#F4F5FA',
      // tableHeaderBg: mode === 'light' ? '#F9FAFC' : '#3D3759',
      overlay: mode === 'light' ? 'rgba(0,0,0,.03)' : 'rgba(255,255,255,.05)',
      // overlay: mode === 'light' ? 'rgba(255,255,255, 0.3)' : 'rgba(255,255,255,.05)',
      overlay2: mode === 'dark' ? darken(background.default, 0.5) : darken(background.default, 0.05),
      divider: mode === 'dark' ? 'rgba(255,255,255,.1)' : 'rgba(0, 0, 0, 0.15)',
      // overlayHover: 'rgba(255,255,255, .03)'
    },
    mode: mode,
    common: {
      black: '#000',
      white: whiteColorHex
    },
    primary: primaryColor,
    secondary: secondaryColor,
    // primary: {
    //   light: primary[400], //'#9E69FD',
    //   main: primary[600], //'#9155FD',
    //   dark: primary[800], //'#804BDF',
    //   contrastText: whiteColor
    // },
    // secondary: {
    //   light: `rgba(${secondaryColor}, 0.5)`,
    //   main: `rgba(${secondaryColor}, 0.6)`,
    //   dark: `rgba(${secondaryColor}, 0.7)`,
    //   contrastText: whiteColor
    // },
    error: {
      light: '#FF6166',
      main: '#FF4C51',
      dark: '#E04347',
      contrastText: whiteColorHex
    },
    warning: {
      light: '#FFCA64',
      main: '#FFB400',
      dark: '#E09E00',
      contrastText: whiteColorHex
    },
    info: {
      light: '#32BAFF',
      main: '#16B1FF',
      dark: '#139CE0',
      contrastText: whiteColorHex
    },
    success: {
      light: '#6AD01F',
      main: '#56CA00',
      dark: '#4CB200',
      contrastText: whiteColorHex
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#F5F5F5',
      A200: '#EEEEEE',
      A400: '#BDBDBD',
      A700: '#616161'
    },
    text: {
      primary: `rgba(${mainColor}, 0.97)`,
      secondary: `rgba(${mainColor}, 0.54)`,
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background,
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.26)`,
      disabledBackground: `rgba(${mainColor}, 0.12)`,
      focus: `rgba(${mainColor}, 0.12)`,

      // active: primaryColor.alpha(.54),
      // hover: primaryColor.alpha(.04),
      // selected: primaryColor.alpha(.08),
      // disabled: primaryColor.alpha(.26),
      // disabledBackground: primaryColor.alpha(.12),
      // focus: primaryColor.alpha(.12),
    }
  }
}

export default DefaultPalette
