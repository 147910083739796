
// @configs
import routes from "@configs/routes";

// mui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// @components
import { ButtonLink } from '@components/Link'

// @data
import { technologiesList, webDevSolutionsList } from '@data/index'
import type { SolutionItem } from "@data/types";


const maxItemsLength = 5

export default function Services() {

  const renderList = (title: string, list: SolutionItem[], hrefMore: string) => {

    const items = list.filter((o, i) => i < maxItemsLength)
    const restItems = list.filter((o, i) => i >= maxItemsLength)

    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {items.map((item, index) => {
          return (
            <Box key={index}>
              <ButtonLink
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 5,
                  typography: 'subtitle2',
                }}
                fullWidth
                href={item?.href || '#'}>
                {item.title}
              </ButtonLink>
            </Box>
          )
        })}
        {restItems.length > 0 && (
          <Box sx={{
            mt: 3,
            pt: 3,
            borderTop: 'solid 1px',
            borderColor: 'divider',
          }}>
            <ButtonLink
              fullWidth
              endIcon={<ArrowForwardIcon />}
              sx={{
                justifyContent: 'flex-start',
                typography: 'subtitle2',
                fontWeight: 'bold',
              }}
              href={hrefMore}>
              See more
            </ButtonLink>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box sx={{
      p: 5,
    }}>
      <Typography variant="h6" gutterBottom>
        Software Development Services.
      </Typography>
      <Typography variant="subtitle2" color="text.secondary">
        Accelerate your tech projects with outsourced development. Flexible engagement models, tailored to you.
      </Typography>
      <Box sx={{
        mt: 5,
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: 5,
      }}>
        {renderList('Technologies.', technologiesList, routes.TECHNOLOGIES.INDEX)}
        {renderList('Expertise.', webDevSolutionsList, routes.SOLUTIONS.INDEX)}
      </Box>
    </Box>
  )
}