import * as React from 'react'

// @core
import { useGlobalContext } from '@core/context';
import env from '@core/env';

// mui
import { ThemeProvider } from '@mui/material/styles';
import themeConfig from '@core/theme/themeConfig';
import GlobalStyles from '@mui/material/GlobalStyles';

// theme
import getTheme from "@core/theme";
import { getCustomStyles } from './customStyles';

// types
import { Mode } from '@core/theme/types'

interface Props {
  children: React.ReactNode
  mode?: Mode
}

export default function CustomThemeProvider({ children, mode }: Props) {

  // context
  // const globalContext = useGlobalContext()

  // states
  // const [themeMode, setThemeMode] = React.useState<Mode>(themeConfig.mode)

  const themeMode = mode || themeConfig.mode

  // React.useEffect(() => {

  //   // Detect color mode from system & set to global context
  //   const themeMode = localStorage.getItem('themeMode') as Mode

  //   let newMode: Mode = 'light'
  //   const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

  //   if (isDarkMode) {
  //     newMode = 'dark'
  //   }

  //   newMode = themeMode || newMode
  //   globalContext.changeThemeMode(newMode)

  // }, [])

  // React.useEffect(() => {
  //   setThemeMode(globalContext.themeMode)
  // }, [globalContext.themeMode])

  const theme = getTheme(themeMode)

  const customStyles = getCustomStyles(theme)

  // return (
  //   <pre>
  //     {JSON.stringify(theme, null, 2)}
  //   </pre>
  // )

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={customStyles} />
      {children}
    </ThemeProvider>
  )
}