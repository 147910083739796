

// i18n
import { PageProps } from "@core/i18n/pageService/types"

// @core
import useTranslate, { tDict } from "@core/i18n/useTranslate";

// @configs
import project from "@configs/project";

// mui
import Box from '@mui/material/Box';

// @snippets
import SeoData from '@core/snippets/SeoData'
import LogoAnimation from "@snippets/LogoAnimation"

// blocks
import AppBar from './AppBar';
import Footer from '../Footer';

// @components
import CrispChat from "@components/CrispChat";


interface AppLayoutProps {
  children: React.ReactNode
  config?: PageProps['config']
}

const AppLayout = ({ config, ...props }: AppLayoutProps) => {

  // hooks
  const { t } = useTranslate();


  if (!config) {
    return (
      <>
        {props.children}
      </>
    )
  }

  if (config) {

    if (config?.blank) {
      return (
        <>
          {props.children}
        </>
      )
    }

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>


        <SeoData
          ns="common"
          title={config?.seo?.title || project.name}
          description={config?.seo?.description}
          image={config?.seo?.image}
        />

        <LogoAnimation />

        {config?.plugins?.chat && (
          <CrispChat
            apiKey={project.crispChatId}
            welcomeMessage="Hi there! How can we help you?"
            position="right"
            color="blue"
          />
        )}

        <Box sx={{
          flex: '1 1 auto',
        }}>
          <AppBar config={config}>
            <>
              {props.children}
            </>
          </AppBar>
        </Box>
        <Footer size={config?.footer?.size} />
      </Box>
    )
  }

}


export default AppLayout