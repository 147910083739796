const translations = {
  "test": "test",
  "menu": {
    "main": "menu.main",
    "privacyPolicy": "menu.privacyPolicy",
    "termsOfService": "menu.termsOfService",
    "account": "menu.account",
    "signup": "menu.signup",
    "signin": "menu.signin",
    "passwordReset": "menu.passwordReset",
    "authError": "menu.authError",
    "userInfo": "menu.userInfo",
    "userAccount": "menu.userAccount"
  },
  "footer": {
    "subtitle": "footer.subtitle"
  },
  "seo": {
    "main": {
      "title1": "seo.main.title1",
      "title2": "seo.main.title2",
      "description": "seo.main.description"
    },
    "about": {
      "title": "seo.about.title",
      "description": "seo.about.description"
    },
    "privacy": {
      "title": "seo.privacy.title"
    },
    "terms": {
      "title": "seo.terms.title"
    }
  },
  "rootLayout": {
    "accountMenuTitle": "rootLayout.accountMenuTitle"
  },
  "auth": {
    "signInButton": "auth.signInButton",
    "logoutButton": "auth.logoutButton",
    "passwordRecovery": {
      "title": "auth.passwordRecovery.title",
      "buttonReset": "auth.passwordRecovery.buttonReset",
      "backToSignIn": "auth.passwordRecovery.backToSignIn",
      "goToSignIn": "auth.passwordRecovery.goToSignIn",
      "checkSpam": "auth.passwordRecovery.checkSpam",
      "titleSuccess": "auth.passwordRecovery.titleSuccess"
    },
    "signIn": {
      "title": "auth.signIn.title",
      "resetPasswordSuccess": "auth.signIn.resetPasswordSuccess",
      "resetPasswordError": "auth.signIn.resetPasswordError",
      "undefinedError": "auth.signIn.undefinedError",
      "registrationButton": "auth.signIn.registrationButton",
      "forgotPassword": "auth.signIn.forgotPassword",
      "resetPasswordButton": "auth.signIn.resetPasswordButton",
      "signInError": "auth.signIn.signInError",
      "otherOptions": "auth.signIn.otherOptions",
      "signInWithProvider": "auth.signIn.signInWithProvider"
    },
    "signUp": {
      "title": "auth.signUp.title",
      "registerButton": "auth.signUp.registerButton",
      "backToSignIn": "auth.signUp.backToSignIn"
    }
  },
  "userAccount": {
    "newPassword": "userAccount.newPassword"
  },
  "accessDenied": {
    "title": "accessDenied.title",
    "message": "accessDenied.message"
  },
  "pages": {
    "_404": {
      "title": "pages._404.title",
      "button": "pages._404.button"
    }
  },
  "snippets": {
    "chat": {
      "welcomeMessage": "snippets.chat.welcomeMessage"
    }
  },
  "defaults": {
    "preview": "defaults.preview",
    "password": "defaults.password",
    "name": "defaults.name",
    "username": "defaults.username",
    "save": "defaults.save",
    "message": "defaults.message",
    "send": "defaults.send",
    "videoLessons": "defaults.videoLessons",
    "theme": "defaults.theme"
  }
};

export default translations;