// @core
import env from '@core/env'

const routes = {
  PAGES: {
    HOME: '/',

    PRIVACY: '/privacy',
    TERMS: '/terms',

    CONTACTS: '/contacts',
    ABOUT: '/about',
    TEAM: '/team',
    TEAM_MEMBER: '/team/[slug]',
    CAREERS: '/careers',
    BLOG: '/blog',

    PORTFOLIO: '/portfolio',
    PORTFOLIO_DETAIL: '/portfolio/[slug]',

  },
  SOLUTIONS: {
    INDEX: '/solutions',
    UNREAL_ENGINE: {
      INDEX: '/solutions/unreal-engine',
      DEMOS: '/solutions/unreal-engine/demos',
      INDUSTRIES: {
        METAVARSES: '/solutions/unreal-engine/industries/metaverses',
        REAL_ESTATE: '/solutions/unreal-engine/industries/real-estate',
        EDUCATION: '/solutions/unreal-engine/industries/education',
        AUTOMOTIVE: '/solutions/unreal-engine/industries/automotive',
      },
      PIXEL_STREAMING: '/solutions/unreal-engine/pixel-streaming',
      CLOUD_RENDERING: '/solutions/unreal-engine/cloud-rendering',
    },
    DEVELOPMENT: {
      DETAILS: '/solutions/development/[slug]',
    },
  },
  TECHNOLOGIES: {
    INDEX: '/technologies',
    DETAILS: '/technologies/[slug]',
  },
  // SERVICES: {
  //   // CONSULTING: '/services/consulting',
  //   // DEVELOPMENT: '/services/development',
  //   // TRAINING: '/services/training',
  // },
  AUTH: {
    SIGNIN: '/auth/signin',
    SIGNOUT: '/api/auth/signout',
    SIGNUP: '/auth/signup',
    ERROR: '/auth/error',
  },
  USER: {
    ACCOUNT: '/user/account',
  },
}

export const routesPreset = {
  userLogged: routes.PAGES.HOME,
}

export default routes 