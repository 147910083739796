import { StateProps } from './initial'

const KEYS = {
  UPDATE: 'UPDATE',
  UPDATE_THEME_MODE: 'UPDATE_THEME_MODE',
}

function Reducer(state: StateProps, action: any) {
  const { type, payload: anValue } = action

  if (type === KEYS.UPDATE) {
    return { ...state, ...anValue }
  }

  else if (type === KEYS.UPDATE_THEME_MODE) {
    state.themeMode = anValue
    return { ...state }
  }

  return state
}

export { Reducer, KEYS }
