import { useEffect, useState } from "react";

// @core
import env from "@core/env";
import { useGlobalContext } from "@core/context";

// mui
import { useTheme, Theme } from "@mui/material/styles"
import Box from '@mui/material/Box';

// libs
import anime from 'animejs'


const LogoAnimation = () => {

  // context
  const { pageUrl } = useGlobalContext()

  // hooks
  const theme = useTheme<Theme>()

  // states
  const [mounted, setMounted] = useState(false)

  const color = theme.palette.primary.main

  useEffect(() => {
    const showed = sessionStorage.getItem('logo-animation')

    if (!showed) {
      sessionStorage.setItem('logo-animation', 'true')
      setMounted(true)
      setTimeout(() => {
        start()
      }, 300)

    }

  }, []);

  const start = () => {

    anime({
      targets: "#logo-root",
      opacity: [0, 1],
      easing: "linear",
      direction: "easeOut",
      duration: 1500,
      delay: 500,
    });

    anime({
      targets: "#dot",
      opacity: [0, 1],
      translateY: [-250, 0],

      easing: "easeOutBounce",
      direction: "easeOut",
      duration: 1000,
      delay: 1500,
    });

    anime({
      targets: "#letterU",
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', color],
      opacity: [1, 1],
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "linear",
      direction: "easeOut",
      duration: 2500,
      delay: 0,
    });

    // disappear logo
    anime({
      targets: "#logo-root",
      opacity: [1, 0],
      easing: "linear",
      direction: "easeOut",
      duration: 500,
      delay: 3000,
    });
  }

  if (!mounted) {
    return null
  }

  return (
    <Box
      id="logo-root"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: (theme: any) => theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
        opacity: 0,
        zIndex: (theme: any) => theme.zIndex.drawer + 1,
        '& svg path': {
          opacity: 0,
        },
      }}>

      <svg
        width="300"
        height="300"
        viewBox="0 0 300 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >

        <path
          id="letterU"
          d="M166.498 41H161.498V46V178.649C161.498 186.559 159.782 193.416 156.471 199.353C153.171 205.27 148.473 209.918 142.262 213.338C136.192 216.65 128.83 218.406 120 218.406C111.175 218.406 103.816 216.652 97.747 213.343C91.6049 209.926 86.8942 205.274 83.5197 199.336C80.2146 193.403 78.5018 186.552 78.5018 178.649V46V41H73.5018H36H31V46V181.795C31 197.488 34.6691 211.509 42.1512 223.689C49.6089 235.83 60.0993 245.275 73.4728 252.025L73.4824 252.03L73.4919 252.035C86.9131 258.736 102.464 262 120 262C137.472 262 152.988 258.735 166.407 252.035L166.417 252.03L166.426 252.025C179.796 245.277 190.284 235.835 197.742 223.699C205.294 211.518 209 197.494 209 181.795V46V41H204H166.498Z" fill="#fff" stroke={color} stroke-width="10" />
        <path
          id="dot"
          d="M249.439 266.662C242.976 266.662 237.436 264.389 232.82 259.844C228.203 255.298 225.931 249.759 226.002 243.224C225.931 236.832 228.203 231.364 232.82 226.818C237.436 222.273 242.976 220 249.439 220C255.689 220 261.122 222.273 265.739 226.818C270.426 231.364 272.806 236.832 272.877 243.224C272.806 247.557 271.669 251.499 269.468 255.05C267.337 258.601 264.496 261.442 260.945 263.572C257.465 265.632 253.629 266.662 249.439 266.662Z" fill={color} />

      </svg>
    </Box>
  );
};


export default function LogoAnimationWrapper() {

  return null

  // if (env.isDev) {
  //   return null
  // }

  return (
    <LogoAnimation />
  );
};