
import * as React from "react";
import { useRouter } from 'next/router';

// mui
import GlobalStyles from '@mui/material/GlobalStyles';
import styles from './styles';


// libs
// import "nprogress/nprogress.css";
import NP from "nprogress";


export default function NProgress() {
  const router = useRouter();

  React.useEffect(() => {
    const handleRouteStart = () => NP.start();
    const handleRouteDone = () => NP.done();

    router.events.on("routeChangeStart", handleRouteStart);
    router.events.on("routeChangeComplete", handleRouteDone);
    router.events.on("routeChangeError", handleRouteDone);

    return () => {
      // Make sure to remove the event handler on unmount!
      router.events.off("routeChangeStart", handleRouteStart);
      router.events.off("routeChangeComplete", handleRouteDone);
      router.events.off("routeChangeError", handleRouteDone);
    };
  }, []);

  return (
    <>
      <GlobalStyles styles={styles} />
    </>
  );
}