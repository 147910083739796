// hooks
import AxiosApi from './AxiosApi';

export default function useApiAuth() {
  const api = new AxiosApi()

  const user = {
    signup: (data: any) => {
      const url = '/djapi/user/signup';
      return api.post(url, data);
    },
    passwordResetRequest: (data: any) => {
      const url = '/djapi/user/password-reset/request';
      return api.post(url, data);
    },
    info: () => {
      const url = '/djapi/user/info';
      return api.get(url);
    },
    update: {
      read: () => {
        const url = '/djapi/user/update';
        return api.get(url);
      },
      update: (data: any) => {
        const url = '/djapi/user/update';
        return api.put(url, data);
      }
    }
  };

  const auth = {
    tgwebapp: (data: any) => {
      const url = '/djapi/user/auth/tgwebapp';
      return api.post(url, data);
    },
    google: (data: any) => {
      const url = '/djapi/user/auth/google';
      return api.post(url, data);
    }
  }

  return {
    user,
    auth,
  };
};

