// @configs
import routes from '@configs/routes';

// types
import type { SubmenuVariant } from './Submenu'

export type MenuItem = {
  title: string;
  href: string;
  variant?: SubmenuVariant;
}


const menuList: Array<MenuItem> = [
  {
    title: 'Our Work',
    href: routes.PAGES.PORTFOLIO,
  },
  {
    title: 'Solutions',
    href: '#',
    variant: 'solutions',
  },
  {
    title: 'Services',
    href: '#',
    variant: 'services',
  },
  {
    title: 'Company',
    href: '#',
    variant: 'company',
  }
]


export default menuList;