import { css } from '@emotion/react';
import { Theme } from '@mui/material/styles';

export const getCustomStyles = (theme: Theme) => {

  const mode = theme.palette.mode;

  return css({

    html: {
      // overflow: 'hidden',
      // overscrollBehavior: 'none',
      fontSize: '120%',
    },
    body: {
      /* Disables pull-to-refresh but allows the page to scroll. */
      overscrollBehaviorYy: 'contain',

      scrollbarWidth: 'thin',
      scrollbarColor: mode === 'dark' ? 'gray black' : 'black gray',

      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: mode === 'dark' ? 'black' : 'gray'
      },
      '&::-webkit-scrollbar-thumb': {
        background: mode === 'dark' ? 'gray' : 'white',
        borderRadius: '6px',
      },
    },
    'strong, b': {
      fontWeight: '600 !important',
    },

  })
}


export default getCustomStyles;