import { useState } from 'react';

// mui
import { useTheme, Theme } from '@mui/material/styles';
import { TextField, Button, Typography } from "@mui/material";
import Box from '@mui/material/Box';

// @modules
import AirtableAPI from "@modules/Airtable";


export default function Subscription() {

  // hooks
  const theme = useTheme<Theme>();
  // const estate = useEstate();

  // states
  const [disabled, setDisabled] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);


  const handleSubscribe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setDisabled(true);

    const body = {
      email: email,
    }

    await AirtableAPI.addWebsiteSubscribe(body).then((res) => {
      if (res.status === 200) {
        // alert('Super!')
        setSuccess(true)
      } else {
        alert('Error!')
        console.error(res.data)
      }

      return res
    })

    setDisabled(false);
  }

  const renderForm = () => {
    return (
      <Box
        component='form'
        onSubmit={handleSubscribe}
      >

        <Typography variant='subtitle2' color="text.secondary" gutterBottom>
          Subscribe To Our Newsletter
        </Typography>

        <Box sx={{
          display: 'flex',
          gap: 2,
        }}>
          <TextField
            required
            disabled={disabled}
            // helperText="We'll never share your email."
            type="email"
            label="Email"
            size='small'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            disabled={disabled}
            type="submit"
            variant="contained"
            color="secondary">
            Subscribe
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {success ? (
        <Box>
          <Typography variant="h6" align="center">
            Thank you for subscribing!
          </Typography>
        </Box>
      ) : (
        renderForm()
      )}
    </Box>
  )
}