import { values } from '../breakpoints'

class Media {

  public max: Record<keyof typeof values, string> = {} as Record<keyof typeof values, string>;
  public min: Record<keyof typeof values, string> = {} as Record<keyof typeof values, string>;

  constructor() {
    const breakpoints = Object.keys(values) as Array<keyof typeof values>;

    for (let i = 0; i < breakpoints.length - 1; i++) {
      const currentBreakpoint = breakpoints[i];
      const nextBreakpoint = breakpoints[i + 1];

      this.max[currentBreakpoint] = `@media (max-width: ${values[nextBreakpoint] - 1}px)`;
      this.min[currentBreakpoint] = `@media (min-width: ${values[currentBreakpoint]}px)`;
    }

    this.max[breakpoints[breakpoints.length - 1]] = `@media (max-width: ${values[breakpoints[breakpoints.length - 1]] - 1}px)`;
    this.min[breakpoints[breakpoints.length - 1]] = `@media (min-width: ${values[breakpoints[breakpoints.length - 1]]}px)`;
  }
}

const media = new Media();
export default media;


/**
 * Usage
 * 
import media from '../utils/media';
cosnst jss = {
  root: {
    [media.min.sm]: {
      opacity: 0.5,
    },
    [media.max.sm]: {
      opacity: 0.5,
    },
  }
}
 */