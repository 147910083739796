export default {
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none'
      },
      rounded: {
        borderRadius: 16
      }
    }
  }
}
