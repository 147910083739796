// ** MUI Imports
import { Theme } from '@mui/material/styles'

// ** Theme Config Imports
import themeConfig from '../themeConfig'
import media from '../utils/media';

const Button = (theme: Theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          borderRadius: 8,
          lineHeight: 1.71,
          letterSpacing: '0.3px',
          padding: `${theme.spacing(1.875, 3)}`,
          [media.max.sm]: {
            padding: `${theme.spacing(1.3, 5.5)}`,
          },
          '&[data-button="rounded"]': {
            borderRadius: 100,
            border: 'solid 3px',
            borderColor: theme.palette.mode === 'light' ? 'rgba(255,255,255,.2)' : 'rgba(0,0,0,.1)'
          }
        },
        contained: {
          boxShadow: theme.shadows[3],
          padding: `${theme.spacing(1.875, 5.5)}`,
          '&.MuiButton-containedWarning': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[800]
          }
        },
        containedSuccess: {
          color: theme.palette.primary.contrastText
        },
        outlined: {
          padding: `${theme.spacing(1.625, 5.25)}`,
          borderColor: theme.palette.divider
        },
        outlinedPrimary: {
          borderColor: theme.palette.primary.main,
        },
        outlinedSecondary: {
          borderColor: theme.palette.divider,
          color: theme.palette.text.primary,
        },
        sizeSmall: {
          padding: `${theme.spacing(1, 2.25)}`,
          [media.max.sm]: {
            fontSize: '0.675rem'
          },
          '&.MuiButton-contained': {
            padding: `${theme.spacing(1, 2.5)}`,
          },
          '&.MuiButton-outlined': {
            padding: `${theme.spacing(0.75, 2.25)}`,
            [media.max.sm]: {
              padding: `${theme.spacing(0.8, 2.5)}`,
            },
          }
        },
        sizeLarge: {
          minHeight: 56,
          padding: `${theme.spacing(2.125, 5.5)}`,
          [media.max.sm]: {
            minHeight: 50,
            fontSize: '0.875rem'
          },
          '&.MuiButton-contained': {
            padding: `${theme.spacing(2.125, 6.5)}`,
            [media.max.sm]: {
              padding: `${theme.spacing(1.6, 4)}`,
            }
          },
          '&.MuiButton-outlined': {
            padding: `${theme.spacing(1.875, 6.25)}`,
            [media.max.sm]: {
              padding: `${theme.spacing(1.6, 4)}`,
            }
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: themeConfig.disableRipple
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-contained:not([disabled])': {
            color: theme.palette.text.secondary
          }
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          '.MuiButtonGroup-grouped:not(:last-of-type):hover': {
            borderColor: theme.palette.divider
          },
        },
      },
    }
  }
}

export default Button
