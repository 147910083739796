
// mui
import { BoxProps } from '@mui/material'
import Box from '@mui/material/Box';

// libs
import { Icon, IconProps } from '@iconify/react'

// Icons:
// https://icon-sets.iconify.design/mdi/

interface Props extends IconProps {
  icon: string
  sx?: BoxProps['sx'] & {
    color?: string
  }
}

const IconifyIcon = ({ icon, sx, ...rest }: Props) => {

  return (
    <Box
      component="span"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '1.5rem',
        ...sx,
        color: 'text.primary',
        '& path': {
          fill: sx?.color,
        },
      }}>
      <Icon
        icon={icon}
        fontSize="inherit"
        {...rest}
      />
    </Box>
  )
}

export default IconifyIcon