import * as React from 'react';
import useRouter from '@hooks/useRouter';
import { useSession } from 'next-auth/react';
import NextLink from 'next/link';

// @core
// import useTranslate, { tDict } from "@core/i18n/useTranslate";
import { PageProps } from "@core/i18n/pageService/types"

// @hooks
import useMedia from '@hooks/useMedia';

// @configs
import project from '@configs/project';
import routes from '@configs/routes';

// mui
import { alpha } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// @components
import { ButtonLink } from '@components/Link';

// @snippets
import SvgLogo from '@snippets/Logotype/SvgLogo';
import ThemeProviderMode from '@core/snippets/ThemeProviderMode';

// blocks
import MobileMenu from './MobileMenu';
import Submenu, { SubmenuRefAccess } from './Submenu'

// data
import menuList from './menuList';


interface Props extends PageProps {
  children: React.ReactNode;
}

function ResponsiveAppBar(props: Props) {

  // refs
  const refSubmenu = React.useRef<SubmenuRefAccess>(null)

  // hooks
  const theme = useTheme<Theme>()
  const router = useRouter()
  const media = useMedia()

  // computed
  const appBarTransparent = props.config?.appBar?.bgcolor === 'transparent'
  const appBarPosition = props.config?.appBar?.position || 'sticky'
  const mode = (appBarTransparent && media.up.sm) ? 'dark' : 'light'
  const isDarkMode = mode === 'dark'

  return (
    <Box sx={{
      position: 'relative',
      bgcolor: 'background.default',
      color: 'text.primary',
    }}>

      <Submenu ref={refSubmenu} />

      <ThemeProviderMode mode={mode}>
        <AppBar
          sx={{
            position: media.down.sm ? 'sticky' : appBarPosition,
            top: 0,
            py: {
              sm: 1,
              md: 2,
            },
            zIndex: 'appBar',
            bgcolor: (theme: any) => {
              if (media.down.sm) {
                // if (appBarTransparent) {
                //   return theme.palette.primary.main
                // }
                return 'background.default'
              }

              if (appBarTransparent) {
                return 'transparent'
              }
              return alpha(theme.palette.background.default, 0.6)
            },
            borderBottom: (theme: any) => {
              if (isDarkMode) return '1px solid rgba(255, 255, 255, 0.1)'
              return `1px solid ${theme.palette.divider}`
            },
            color: (theme: any) => {
              if (media.down.sm) {
                return 'text.primary'
              }
              if (isDarkMode) {
                return 'common.white'
              }
              return 'text.primary'
            },
            boxShadow: 'none',
            backdropFilter: 'blur(10px)',
          }}>
          <Container
            maxWidth={false}
            sx={{
              px: {
                xs: 5,
                // xs: 'none',
              }
            }}>
            <Toolbar disableGutters>

              <Box sx={{
                mr: 10,
                display: { xs: 'none', md: 'flex' },
              }}>
                <SvgLogo
                  title={project.name}
                  component={NextLink}
                  href="/"
                  sx={{
                    width: 100,
                    mb: '3px',
                  }}
                  color={isDarkMode ? '#fff' : theme.palette.text.primary}
                />
              </Box>

              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}>
                  <MobileMenu>
                    <IconButton
                      size="large"
                      // onClick={handleOpenNavMenu}
                      color="inherit"
                      sx={{
                        ml: -2,
                        mr: 2,
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </MobileMenu>
                </Box>
              </Box>
              <Box sx={{
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                justifyContent: 'flex-start',
              }}>
                <SvgLogo
                  title={project.name}
                  component={NextLink}
                  href="/"
                  sx={{
                    width: 80,
                  }}
                  color={isDarkMode ? '#fff' : theme.palette.text.primary}
                />
              </Box>
              <Box sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
              }}>
                {menuList.map((page, index) => (
                  <Button
                    key={index}
                    endIcon={page.variant ? <ExpandMoreIcon /> : null}
                    onMouseEnter={(event: React.MouseEvent<HTMLElement>) => {
                      if (page.variant) {
                        refSubmenu.current?.open(event, page.variant)
                      } else {
                        refSubmenu.current?.close()
                      }
                    }}
                    LinkComponent={!page.variant ? NextLink : undefined}
                    href={!page.variant ? page.href : undefined}
                    variant={router.pathname.startsWith(page.href) ? 'outlined' : 'text'}
                    color="inherit"
                    sx={{
                      fontSize: '1rem',
                      minWidth: 'auto',
                      my: 2,
                    }}
                  >
                    {page.title}
                  </Button>
                ))}
              </Box>

              <Box sx={{
                flexGrow: 0,
              }}>

                <ButtonLink
                  href={routes.PAGES.CONTACTS}
                  variant="contained"
                  disableElevation
                  data-button="rounded"
                  sx={{
                    bgcolor: isDarkMode ? 'common.white' : 'primary.main',
                    color: (theme: any) => theme.palette.getContrastText(theme.palette.text.primary),
                    boxShadow: 'none',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      bgcolor: isDarkMode ? 'common.white' : 'primary.main',
                      // color: 'common.white',
                    }
                  }}>
                  Request a quote
                </ButtonLink>

                {/* <LaguageMenu />
                  <AccoutMenu /> */}
              </Box>

            </Toolbar>

          </Container>

        </AppBar>
      </ThemeProviderMode>


      <Box
        sx={{
          flexGrow: 1,
        }}>
        <ChildrenContainer {...props}>
          <ProtectedContent {...props} />
        </ChildrenContainer>
      </Box>

    </Box>
  );
}

const ChildrenContainer = (props: Props) => {

  if (props.config?.appBar?.noContainer === true) {
    return (
      <>
        {props.children}
      </>
    )
  }

  return (
    <Container maxWidth="xl" sx={{
      py: {
        xs: 5,
        sm: 10,
      },
    }}>
      {props.children}
    </Container>
  )
}

const ProtectedContent = (props: Props) => {

  // hooks
  const router = useRouter()
  const { data: session, status } = useSession()

  React.useEffect(() => {

    if (props.config?.protected && status === 'unauthenticated') {
      router.push({
        pathname: routes.AUTH.SIGNIN,
        query: {
          callbackUrl: router.pathname,
        },
      })
    }

  }, [status, router.pathname])

  if (props.config?.protected && !session) {
    return <div />
  }

  return (
    <>
      {props.children}
    </>
  )
}





const RootLayout = (props: Props) => {
  return (
    <ResponsiveAppBar {...props} />
  )
}

export default RootLayout;
