


const env = new class {
  isDev: boolean
  isProd: boolean | string
  isStaging: boolean | string

  API_URL: string
  DOMAIN: string

  staticPath: (...args: any[]) => void
  staticUrl: (...args: any[]) => void

  cdnStore: (slug: string) => string

  constructor() {
    // this.VERSION = 1 // process.env.npm_package_version || '0'

    this.API_URL = process.env.DJANGO_API_URL || ''
    this.DOMAIN = process.env.NEXTAUTH_URL || ''

    this.isDev = process.env.IS_DEV || process.env.NODE_ENV !== 'production' ? true : false
    this.isProd = process.env.isProd || !this.isDev

    this.isStaging = process.env.isStaging || false

    this.staticPath = (...v) => `/static/${v.join('/')}`
    this.staticUrl = (...v) => this.DOMAIN + this.staticPath(...v)

    this.cdnStore = (slug: string) => `${process.env.CDN_STORE_URL}/${slug}`

  }

  get data() {
    return {
      DJANGO_API_URL: process.env.DJANGO_API_URL || '',
      NEXTAUTH_URL: process.env.NEXTAUTH_URL || '',
      GOOGLE_ID: process.env.GOOGLE_ID || '',
      GOOGLE_SECRET: process.env.GOOGLE_SECRET || '',
      GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY || '',
      CDN_STORE_URL: process.env.CDN_STORE_URL || '',

      AIRTABLE_API_KEY: process.env.AIRTABLE_API_KEY || '',
      AIRTABLE_BASE_ID: process.env.AIRTABLE_BASE_ID || '',
      TELEGRAM_BOT_NAME: process.env.TELEGRAM_BOT_NAME || '',
      TELEGRAM_BOT_TOKEN: process.env.TELEGRAM_BOT_TOKEN || '',
      TELEGRAM_CHANNEL_ID: process.env.TELEGRAM_CHANNEL_ID || '',
    }
  }

}

export default env
