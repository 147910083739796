// ** MUI Imports
import { Theme } from '@mui/material/styles'

// ** Theme Config Imports
import media from '../utils/media';

const select = (theme: Theme) => ({
  MuiSelect: {
    styleOverrides: {
      select: {
        minWidth: '6rem !important',
        [media.max.sm]: {
          fontSize: '0.875rem',
          padding: `${theme.spacing(2.5, 4)}`,
        },
        '&.MuiTablePagination-select': {
          minWidth: '1.5rem !important'
        }
      },
      input: {
        // [media.max.sm]: {
        //   fontSize: '0.875rem',
        //   padding: `${theme.spacing(2.5, 4)}`,
        // },
      }
    }
  },
})

export default select
