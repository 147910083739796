import { useRouter as useNextRouter, NextRouter } from 'next/router';

interface ExtendedRouter extends NextRouter {
  pushWithLocale: (url: string, as?: string, options?: any) => Promise<boolean>;
  routeToPathname: (path: string) => string;
}

const useRouter = (): ExtendedRouter => {
  const router = useNextRouter() as ExtendedRouter;

  const routeToPathname = (path: string): string => {
    const convertedPath = path.replace(/\/:([^/]*)/g, '/[$1]');
    return convertedPath;
  };

  const pushWithLocale = async (url: string, as?: string, options?: any): Promise<boolean> => {
    const { locale } = router;
    return router.push(url, as, { ...options, locale });
  };

  return {
    ...router,
    pushWithLocale,
    routeToPathname,
  };
};

export default useRouter;
