import { Mode, Skin } from './types'

export interface ThemeConfig {
  mode: Mode
  skin: Skin
  // themeColor: ThemeColor
  disableRipple: boolean
}

const themeConfig: ThemeConfig = {
  mode: 'light',
  skin: 'bordered',
  // themeColor: 'primary',
  disableRipple: false,
}

export default themeConfig