// hooks
import AxiosApi from '../AxiosApi';

// types
import type { ResponsePromises } from '../types'

import type {
  UnrealEngineFormRequest,
  ContactFormRequest,
} from './types';


export default function useBackend() {
  const api = new AxiosApi();

  const UNREAL_ENGINE = {
    demoRequest: (body: UnrealEngineFormRequest) => {
      return api.post('/djapi/unrealos/request/form/', body) as ResponsePromises<UnrealEngineFormRequest>
    },
    contactSend: (body: ContactFormRequest) => {
      return api.post('/djapi/unrealos/contact/form/', body) as ResponsePromises<ContactFormRequest>
    },
  }

  const SCHEDULE = {
    sendForm: (body: any) => {
      return api.post('/djapi/schedule/form/', body) as ResponsePromises<any>
    }
  }


  return {
    UNREAL_ENGINE,
    SCHEDULE,
  }
};

