import * as React from "react";
import { useRouter } from 'next/router';

// mui
import { Popover } from "@mui/material";
import Box from '@mui/material/Box';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

// blocks
import Company from "./Company";
import Solutions from "./Solutions";
import Services from "./Services";

export interface SubmenuRefAccess {
  open: (event: React.MouseEvent<HTMLElement>, variant: SubmenuVariant) => void;
  close: () => void;
}

interface Props {

}

export type SubmenuVariant = 'solutions' | 'services' | 'company'

const Submenu = React.forwardRef((props: Props, ref: React.Ref<SubmenuRefAccess>) => {

  // refs
  const refTimeout = React.useRef<NodeJS.Timeout | null>(null)

  // states
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [variant, setVariant] = React.useState<null | SubmenuVariant>(null)

  // hooks
  const router = useRouter();

  React.useEffect(() => {
    const handleRouteStart = () => {
      handleClose()
    }

    router.events.on("routeChangeStart", handleRouteStart);

    return () => {
      // Make sure to remove the event handler on unmount!
      router.events.off("routeChangeStart", handleRouteStart);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>, variant: SubmenuVariant) => {
    clearTimeout(refTimeout.current as NodeJS.Timeout)
    setAnchorEl(event.currentTarget);
    setVariant(variant)
  };

  const handleClose = () => {
    clearTimeout(refTimeout.current as NodeJS.Timeout)
    setAnchorEl(null);
  };

  const handleMouseOut = () => {
    clearTimeout(refTimeout.current as NodeJS.Timeout)
    refTimeout.current = setTimeout(() => {
      handleClose()
    }, 500)
  }

  const open = Boolean(anchorEl);


  React.useImperativeHandle(ref, (): SubmenuRefAccess => ({
    open: (event: React.MouseEvent<HTMLElement>, variant: SubmenuVariant) => {
      handleClick(event, variant)
    },
    close: () => {
      handleClose()
    },
  }))

  return (
    <Box>

      <ClickAwayListener onClickAway={(event) => {
        if (event.target === anchorEl) return
        handleClose()
      }}>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onMouseEnter={(event) => {
            clearTimeout(refTimeout.current as NodeJS.Timeout)
          }}
          onMouseLeave={handleMouseOut}
          elevation={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            pointerEvents: 'none',
            '& .MuiPaper-root': {
              pointerEvents: 'all',
              border: 0,
              maxWidth: 600,
              boxShadow: 2,
            },
          }}
        >
          {variant === 'company' && <Company />}
          {variant === 'solutions' && <Solutions />}
          {variant === 'services' && <Services />}

        </Popover>

      </ClickAwayListener>

    </Box>
  )
})

export default Submenu