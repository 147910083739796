


import type { Mode } from '@core/theme/types'
import type { UserInfo } from './types'


export interface StateProps {
  userInfo: UserInfo | null;
  themeMode: Mode;
}

export const initialState: StateProps = {
  userInfo: null,
  themeMode: 'light',
}