export const values = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536
}

const breakpoints = () => ({ values })
export default breakpoints
