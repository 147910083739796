
// @configs
import routes from "@configs/routes";

// types
import type { SolutionItem } from "./types";


const makeHref = (slug: string) => routes.SOLUTIONS.DEVELOPMENT.DETAILS.replace('[slug]', slug);


export const webDevSolutionsList: SolutionItem[] = [
  {
    title: 'Custom Software \nDevelopment',
    description: 'Create custom software tailored for your unique needs, including front-end, and core back-end technology.',
    icon: 'carbon:code',
    href: makeHref('custom-software-development'),
  },
  {
    title: 'QA and \nTesting',
    description: 'Make your technology bulletproof, with manual and automated testing.',
    icon: 'file-icons:test-generic',
    href: makeHref('qa-testing'),
  },
  {
    title: 'AI and \nData Science',
    description: 'Use leading AI, machine learning, and data engineering technologies to unlock business value.',
    icon: 'fluent:brain-circuit-20-regular',
    href: makeHref('ai-data-science'),
  },
  {
    title: 'Mobile App \nDevelopment',
    description: 'Build performant, scalable, and secure mobile applications for iOS and Android devices.',
    icon: 'carbon:application-mobile',
    href: makeHref('mobile-app-development'),
  },
  {
    title: 'UX/UI \nDesign',
    description: 'Create beautiful, pixel-perfect, and easy-to-use designs that delight your end users.',
    icon: 'icon-park-outline:graphic-design',
    href: makeHref('ux-ui-design'),
  },
  {
    title: 'Platform and \nInfrastructure',
    description: 'Ensure applications are secure, fault tolerant and highly available with our DevOps and Security engineers.',
    icon: 'carbon:ibm-cloud-virtual-server-classic',
    href: makeHref('platform-infrastructure'),
  },
  {
    title: 'Parser & \nScraper',
    description: 'Parse and scrape data from any website or source.',
    icon: 'carbon:code',
    href: makeHref('parser-scraper'),
  },
  {
    title: 'Bots & \nAutomation',
    description: 'Automate your business processes with bots.',
    icon: 'carbon:code',
    href: makeHref('bots-automation'),
  },
  {
    title: 'iGaming \n(games)',
    description: 'We develop games for iGaming industry.',
    icon: 'carbon:code',
    href: makeHref('igaming-games'),
  },
  {
    title: 'iGaming \n(casinos)',
    description: 'We develop professional online casinos.',
    icon: 'carbon:code',
    href: makeHref('igaming-casinos'),
  },
  {
    title: 'AI \nSolutions',
    description: 'We develop AI solutions for your business.',
    icon: 'carbon:code',
    href: makeHref('ai-solutions'),
  },
  {
    title: 'Interactive \n3D',
    description: 'Hyper-realistic 3D in your browser.',
    icon: 'carbon:code',
    href: makeHref('interactive-3d'),
  },
  {
    title: 'Payment \nGateways',
    description: 'Professional payment gateways development.',
    icon: 'carbon:code',
    href: makeHref('payment-gateways'),
  },
  {
    title: 'NFTs \n(marketplaces)',
    description: 'Software for NFTs marketplaces.',
    icon: 'carbon:code',
    href: makeHref('nfts-marketplaces'),
  },
  {
    title: 'Browser \nExtensions',
    description: 'Serious browser extensions development.',
    icon: 'carbon:code',
    href: makeHref('browser-extensions'),
  },
  {
    title: 'Desktop \nApps',
    description: 'Desktop apps for Windows, macOS and Linux.',
    icon: 'carbon:code',
    href: makeHref('desktop-apps'),
  },
  {
    title: 'Mobile \nApps',
    description: 'Mobile apps for iOS and Android.',
    icon: 'carbon:code',
    href: makeHref('mobile-apps'),
  },
  {
    title: 'Web Apps & \nPWAs',
    description: 'Web apps and PWAs development.',
    icon: 'carbon:code',
    href: makeHref('web-apps-pwas'),
  },
  {
    title: 'Custom \nSoftware',
    description: 'Custom software development.',
    icon: 'carbon:code',
    href: makeHref('custom-software'),
  }
]





// type ListItem = {
//   title: string,
//   slug?: string,
//   href?: string,
// }


// export const solutionsList: ListItem[] = [
//   {
//     title: 'AI & Machine learning',
//     slug: 'ai-machine-learning',
//   },
//   {
//     title: 'Web development',
//     slug: 'web-development',
//   },
//   {
//     title: 'Mobile development',
//     slug: 'mobile-development',
//   },
//   {
//     title: 'Desktop development',
//     slug: 'desktop-development',
//   },
// ]
