
/**
 * Docs:
 * https://www.npmjs.com/package/airtable
 */

// @core
import env from '@core/env';

// libs
import moment from 'moment';
import axios, { AxiosResponse } from 'axios';


export interface AddRecordFields {
  [key: string]: any;
}

export interface RecordData {
  table: string;
  fields: AddRecordFields;
  email?: {
    to?: string;
    subject: string;
    textPlain: string;
  };
}

class AirtableAPI {
  private baseURL: string;

  constructor() {
    this.baseURL = env.DOMAIN;
  }

  private async addRecord(data: RecordData): Promise<AxiosResponse> {
    try {
      const response = await axios.post(`${this.baseURL}/api/airtable/add`, data);
      return response;
    } catch (error: any) {
      throw new Error(`Error adding record: ${error.message}`);
    }
  }

  public async addWebsiteSubscribe(fields: {
    email: string;
  }): Promise<AxiosResponse> {

    return this.addRecord({
      table: 'website:subscribe',
      fields: {
        Email: fields.email,
      }
    })
  }

  public async addWebsiteRequest(fields: {
    name: string;
    email: string;
    company: string;
    budget: string;
    description: string;
  }): Promise<AxiosResponse> {

    return this.addRecord({
      table: 'website:requests',
      fields: {
        Name: fields.name,
        Email: fields.email,
        Company: fields.company,
        Budget: fields.budget,
        Description: fields.description,
      }
    })
  }

  // public async addWebsiteSchedule(fields: {
  //   teamMember: string;
  //   teamMemberEmail?: string;
  //   contactDateTime: moment.Moment;
  //   customerTimezone: string;
  //   customerName: string;
  //   customerMessage: string;
  //   email: string;
  // }): Promise<AxiosResponse> {

  //   // Format date with time & timezone
  //   // from: Thu Sep 28 2023 02:00:00 GMT+0300
  //   // to: Fri Dec 20 2019 16:10:09 GMT-0700 (Mountain Standard Time)
  //   const contactDateTime = fields.contactDateTime.format('ddd MMM DD YYYY HH:mm:ss ZZ');

  //   return this.addRecord({
  //     table: 'website:schedule',
  //     fields: {
  //       TeamMember: fields.teamMember,
  //       ContactDateTime: contactDateTime,
  //       CustomerTimezone: fields.customerTimezone,
  //       CustomerName: fields.customerName,
  //       CustomerMessage: fields.customerMessage,
  //       Email: fields.email,
  //     },
  //     // email: {
  //     //   to: fields.teamMemberEmail,
  //     //   subject: 'New schedule request',
  //     //   textPlain: `
  //     //     Team member: ${fields.teamMember}
  //     //     Contact date & time: ${contactDateTime}
  //     //     Customer timezone: ${fields.customerTimezone}
  //     //     Customer name: ${fields.customerName}
  //     //     Customer message: ${fields.customerMessage}
  //     //     Email: ${fields.email}
  //     //   `
  //     // },
  //   })

  // }

  public async addWebsiteRequestUE(fields: {
    name: string;
    email: string;
    company: string;
    website: string;
    category: string;
    country: string;
    hasProduct: string;
    message: string;
  }): Promise<AxiosResponse> {

    return this.addRecord({
      table: 'website:requests:ue',
      fields: {
        Name: fields.name,
        Email: fields.email,
        Company: fields.company,
        Website: fields.website,
        Category: fields.category,
        Country: fields.country,
        HasProduct: fields.hasProduct,
        Message: fields.message,
      }
    })
  }
}


export default new AirtableAPI();