
// @configs
import routes from "@configs/routes";

// types
import type { SolutionItem } from "./types";


export const unrealEngineSolutionsList: SolutionItem[] = [
  {
    title: 'Overview',
    icon: 'material-symbols:info-outline',
    href: routes.SOLUTIONS.UNREAL_ENGINE.INDEX,
  },
  {
    title: 'Industries',
    icon: 'material-symbols:stars-outline',
    href: routes.SOLUTIONS.UNREAL_ENGINE.INDUSTRIES.METAVARSES,
  },
  {
    title: 'Pixel Streaming',
    icon: 'solar:play-stream-bold',
    href: routes.SOLUTIONS.UNREAL_ENGINE.PIXEL_STREAMING,
  },
  {
    title: 'Cloud Rendering',
    icon: 'tabler:cloud-cog',
    href: routes.SOLUTIONS.UNREAL_ENGINE.CLOUD_RENDERING,
  },
  {
    title: 'Demos',
    icon: 'iconoir:3d-select-face',
    href: routes.SOLUTIONS.UNREAL_ENGINE.DEMOS,
  },
]



export const librarieslist: SolutionItem[] = [
  {
    title: 'MetaEditor for Unreal Engine',
    icon: 'carbon:game-console',
    href: 'http://metaeditor.io',
  },
  {
    title: 'Pixel Streaming Player',
    icon: 'carbon:game-console',
    href: 'http://player.metaeditor.io',
  },
  {
    title: 'Pixel Streaming Plugin for Unreal Engine',
    icon: 'simple-icons:unrealengine',
    href: '#',
  }
]
