import { createTheme, responsiveFontSizes } from '@mui/material';

// ** Theme Override Imports
import { typography } from './typography';
import spacing from './spacing'
import shadows from './shadows'
import breakpoints from './breakpoints'
import overrides from './overrides'
import palette from './palette'

// types
import { Mode, ThemeColors } from './types'

// config
import themeConfig from './themeConfig';


const { mode: defaultMode } = themeConfig


export default function createAppTheme(mode: Mode = defaultMode, colors?: ThemeColors) {

  const theme = createTheme({
    palette: palette(mode, colors),
    shadows: shadows(mode),
    ...spacing,
    breakpoints: breakpoints(),
    shape: {
      borderRadius: 8,
    },
    typography,
    mixins: {
      toolbar: {
        minHeight: 64
      }
    },
  });

  const themeOverrides = {
    ...theme,
    components: {
      ...overrides(theme, themeConfig),
    },
  }

  return responsiveFontSizes(themeOverrides, {
    // breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    // disableAlign: true,
    // factor: 2,
  });
}
