
import NextLink from 'next/link';

// @configs
import routes from '@configs/routes';

// mui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// @components
import IconifyIcon from '@components/IconifyIcon';
import { ButtonLink } from '@components/Link';


// @data
import { unrealEngineSolutionsList, webDevSolutionsList } from '@data/index'

const maxItemsLength = 5

type ListItem = {
  title: string;
  description: string;
  href: string;
  icon: string;
  items: {
    title: string;
    icon: string;
    href: string;
  }[]
}

export default function Solutions() {
  const list: ListItem[] = [
    {
      title: 'Web development',
      description: 'Open Source Libraries & Modules',
      href: routes.SOLUTIONS.INDEX,
      icon: 'simple-icons:react',
      items: webDevSolutionsList,
    },
    {
      title: 'Unreal Engine',
      description: 'Hyper-realistic 3D in real-time',
      href: routes.SOLUTIONS.UNREAL_ENGINE.INDEX,
      icon: 'simple-icons:unrealengine',
      items: unrealEngineSolutionsList,
    },
  ]

  return (
    <Box sx={{
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      p: 5,
      gap: 5,
      '& [data-link]': {
        cursor: 'pointer',
        color: 'text.primary',
        textDecoration: 'none',

        border: '1px solid',
        borderColor: 'transparent',
        borderRadius: 1,

        transition: 'border-color 0.2s ease-in-out',

        '&:hover': {
          borderColor: 'divider',
          color: 'primary.main',
        }

      }
    }}>
      {list.map((item, index) => {
        const items = item.items.filter((o, i) => i < maxItemsLength)
        const restItems = item.items.filter((o, i) => i >= maxItemsLength)

        return (
          <Box key={index}>
            <Box
              component={NextLink}
              href={item.href}
              data-link
              sx={{
                // bgcolor: 'customColors.overlay',
                display: 'flex',
                alignItems: 'center',
                p: 3,
                gap: 3,

              }}>
              <IconifyIcon icon={item.icon} width={40} color="inherit" />
              <Box>
                <Typography variant="h6">
                  {item.title}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {item.description}
                </Typography>
              </Box>

            </Box>
            <Box sx={{
              mt: 3,
              pt: 3,
              borderTop: 'solid 1px',
              borderColor: 'divider',
            }}>
              {items.map((item, index) => {
                return (
                  <ButtonLink
                    key={index}
                    href={item.href}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: 5,
                      typography: 'subtitle2',
                    }}>
                    {/* <IconifyIcon icon={item.icon} /> */}
                    {item.title}
                  </ButtonLink>
                )
              })}
              {restItems.length > 0 && (
                <Box sx={{
                  mt: 3,
                  pt: 3,
                  borderTop: 'solid 1px',
                  borderColor: 'divider',
                }}>
                  <ButtonLink
                    fullWidth
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      justifyContent: 'flex-start',
                      typography: 'subtitle2',
                      fontWeight: 'bold',
                    }}
                    component={NextLink}
                    href={item.href}>
                    See more
                  </ButtonLink>
                </Box>
              )}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}