import * as React from 'react'
import useRouter from '@hooks/useRouter';

// reducers
import { Reducer, KEYS } from './reducer'
import { initialState, StateProps } from './initial'

// @configs
import routes from '@configs/routes';

// @core
import { useApiAuth } from "@core/api";

// next-auth
import { useSession } from "next-auth/react"


interface Props {
  pageProps: Record<string, any>
}

const actions = (props: Props): ActionProps => {

  // states
  const [state, dispatch]: [StateProps, any] = React.useReducer(Reducer, initialState)

  // hooks
  const router = useRouter()
  const { status } = useSession()
  const apiAuth = useApiAuth()

  // effects

  React.useEffect(() => {

    if (status === 'loading') return

    if (status === 'unauthenticated') {
      cls.fetchUserInfo(false)
      return
    }

    if (status === 'authenticated') {
      cls.fetchUserInfo(true)
    }

  }, [status])

  const cls = new class {
    get state() {
      return state
    }

    get pageProps() {
      return props.pageProps
    }

    get pageUrl() {
      return props.pageProps?.router?.asPath || router.asPath
    }

    dispatchUpdate(payload: unknown) {
      dispatch({
        type: KEYS.UPDATE,
        payload,
      })
    }

    fetchUserInfo(load: boolean) {
      if (!load) {
        dispatch({
          type: KEYS.UPDATE,
          payload: {
            userInfo: null,
          },
        })
        return
      }

      apiAuth.user.info().then((res) => {
        if (res.status === 200) {
          dispatch({
            type: KEYS.UPDATE,
            payload: {
              userInfo: res.data,
            },
          })
        }
      })
    }

    get userIsLogged() {
      if (status === 'authenticated' && state.userInfo) return true
      return false
    }

    userSignIn(redirect: boolean = false) {
      // signIn()
      const callbackUrl = redirect ? router.asPath : undefined

      router.push({
        pathname: routes.AUTH.SIGNIN,
        query: {
          callbackUrl,
        },
      })
    }

    userSignOut() {
      cls.fetchUserInfo(false)

      // signOut({
      //   callbackUrl: `${window.location.origin}/auth/signin`
      // })

      router.push(routes.AUTH.SIGNOUT)
    }

    get themeMode() {
      return state.themeMode
    }

    changeThemeMode(mode: StateProps['themeMode']) {
      dispatch({
        type: KEYS.UPDATE_THEME_MODE,
        payload: mode,
      })

      localStorage.setItem('themeMode', mode)
    }

  }

  return cls
}

export interface ActionProps {
  state: StateProps
  pageProps: Record<string, any>
  pageUrl: string
  fetchUserInfo: (load: boolean) => void
  userIsLogged: boolean
  userSignIn: (redirect?: boolean) => void
  userSignOut: () => void
  themeMode: StateProps['themeMode']
  changeThemeMode: (mode: StateProps['themeMode']) => void
}

export default actions