import { useState } from "react";

// @core
import useTranslate, { tDict } from "@core/i18n/useTranslate";
import SvgLogo from '@snippets/Logotype/SvgLogo'

// @configs
import routes from '@configs/routes'
import project from "@configs/project";

// @hooks
import useMedia from '@hooks/useMedia';

// mui
import { Container, Grid, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

// icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// libs
import moment from "moment";

// @components
import { Link } from '@components/Link'

// @snippets
import ThemeProviderMode from '@core/snippets/ThemeProviderMode';
import EpicGamesLogo from '@snippets/EpicGamesLogo'

// blocks
import Subscription from './Subscription'

// @data
import { unrealEngineSolutionsList, webDevSolutionsList } from '@data/index'


interface ListProps {
  data: MenuProps
}

const List = ({ data }: ListProps) => {

  // hooks
  const { t } = useTranslate();
  const media = useMedia()

  // states
  const [open, setOpen] = useState(false)

  // computed
  const splitAfter = 10
  const divideTwoColumns = (items: MenuItem[]) => {
    const items1 = items.slice(0, splitAfter)
    const items2 = items.slice(splitAfter)
    return {
      items1,
      items2,
    }
  }
  const { items1, items2 } = divideTwoColumns(data.items)

  const renderItems = (items: MenuItem[]) => {

    return (
      <Stack spacing={2}>
        {items.map((item, index) => (
          <Link
            key={index}
            href={item.href}
            color="text.primary">
            <Typography variant="subtitle1">
              {item.label}
            </Typography>
          </Link>
        ))}
      </Stack>
    )
  }

  if (media.down.md) {
    return (
      <Box>
        <Box
          onClick={() => setOpen(!open)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            py: 2,
          }}>
          <Typography variant="button" color="text.secondary">
            {data.header}
          </Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'text.secondary',
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s',
          }}>
            {open ? <RemoveIcon /> : <AddIcon />}
          </Box>
        </Box>
        <Collapse in={open}>
          <Box sx={{
            pb: 5,
          }}>
            {renderItems(items1)}
            {renderItems(items2)}
          </Box>
        </Collapse>
      </Box>
    )
  }

  return (
    <Box>
      <Typography variant="button" color="text.secondary">
        {data.header}
      </Typography>
      <Box sx={{
        borderTop: '1px solid',
        borderColor: 'divider',
        pt: 5,
        gap: 3,
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          flexGrow: 1,
        }
      }}>
        {renderItems(items1)}
        {renderItems(items2)}
      </Box>
    </Box>
  )
}

interface FooterProps {
  size?: 'hidden' | 'small' | 'default'
}

export default function View({ size = 'default' }: FooterProps) {

  // hooks
  const { t } = useTranslate();

  if (size === 'hidden') {
    return null
  }

  if (size === 'small') {
    return (
      <Box sx={{
        bgcolor: 'customColors.overlay',
        py: 5,
        mt: 20,
      }}>
        <Container maxWidth="xl">

          <Grid container spacing={5}>
            <Grid item flexGrow={1}>
              <SvgLogo />
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={5}>

                <Link
                  href={routes.PAGES.HOME}
                  color="text.secondary">
                  <Typography variant="subtitle2">
                    {t(tDict.common.menu.main)}
                  </Typography>
                </Link>

                {/* <Link
                  href={routes.PAGES.HELP}
                  color="text.secondary">
                  <Typography variant="subtitle2">
                    {t(tDict.common.menu.helpCenter)}
                  </Typography>
                </Link> */}

              </Stack>
            </Grid>
          </Grid>

        </Container>
      </Box>
    )
  }

  return (
    <ThemeProviderMode mode="dark">
      <Box
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          mt: {
            xs: 0,
            md: 20,
          },
          pt: {
            xs: 10,
            md: 20,
          },
          borderTop: '1px solid',
          borderColor: 'divider',
        }}>

        <Box>
          <Container maxWidth="xl">

            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: {
                xs: 'column',
                lg: 'row',
              }
            }}>
              <Box>
                <SvgLogo
                  href="/"
                  sx={{
                    width: {
                      xs: 110,
                      md: 160,
                    },
                  }} />
                <Box sx={{
                  py: 10,
                }}>
                  <EpicGamesLogo />
                </Box>
                <Subscription />
              </Box>
              <Box sx={{
                mt: {
                  xs: 10,
                  lg: 0,
                },
                width: {
                  xs: 'auto',
                  lg: '70%',
                },
                display: 'flex',
                justifyContent: 'space-between',
                gap: {
                  xs: 0,
                  md: 10,
                },
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                '& > *': {
                  flexGrow: 1,
                }
              }}>
                <List data={menuCompany} />
                <List data={menuWebDev} />
                <List data={menuUnrealEngine} />
              </Box>
            </Box>

            <Box sx={{
              my: 15,
              py: 5,
              borderTop: '1px solid',
              borderColor: 'divider',
            }}>

              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },

              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: {
                    xs: 'column',
                    md: 'row',
                  },
                  flexWrap: 'wrap',
                  gap: 3,
                  alignItems: {
                    xs: 'flex-start',
                    md: 'center',
                  },
                  typography: 'subtitle2',
                  color: 'text.secondary',
                }}>
                  <Box>
                    ©{moment().year()} {project.contacts.name}
                  </Box>
                  <Box>
                    <Link
                      color="text.secondary"
                      href={routes.PAGES.PRIVACY}>
                      Privacy Policy
                    </Link>
                  </Box>
                  <Box>
                    <Link
                      color="text.secondary"
                      href={routes.PAGES.TERMS}>
                      Terms of Service
                    </Link>
                  </Box>
                </Box>

                {/* <Stack direction="row" spacing={5} flexWrap="wrap">
              {Object.entries(languages).map(([locale, lang], index) => (
                <Link
                  key={index}
                  href={routes.PAGES.HOME}
                  locale={locale}
                  color="text.secondary">
                  <Typography variant="subtitle2">
                    {lang}
                  </Typography>
                </Link>
              ))}
            </Stack> */}
              </Box>
            </Box>

          </Container>
        </Box>
      </Box>
    </ThemeProviderMode>
  )
}





interface MenuItem {
  label: string
  href: string
}

interface MenuProps {
  header: string
  items: MenuItem[]
}

const menuWebDev: MenuProps = {
  header: 'Development Solutions',
  items: [
    ...webDevSolutionsList.map((item) => ({
      label: item.title,
      href: item.href,
    })),
  ]
}

const menuUnrealEngine: MenuProps = {
  header: '3D Solutions',
  items: [
    ...unrealEngineSolutionsList.map((item) => ({
      label: item.title,
      href: item.href,
    })),
  ]
}

const menuCompany: MenuProps = {
  header: 'Company',
  items: [
    {
      label: 'About',
      href: routes.PAGES.ABOUT,
    },
    {
      label: 'Our Work',
      href: routes.PAGES.PORTFOLIO,
    },
    {
      label: 'Technologies',
      href: routes.TECHNOLOGIES.INDEX,
    },
    {
      label: 'Solutions',
      href: routes.SOLUTIONS.INDEX,
    },
    // {
    //   label: 'Blog',
    //   href: routes.PAGES.BLOG,
    // },
    {
      label: 'Our Team',
      href: routes.PAGES.TEAM,
    },
    {
      label: 'Careers',
      href: routes.PAGES.CAREERS,
    },
    {
      label: 'Contact Us',
      href: routes.PAGES.CONTACTS,
    }
  ]
}