import { useEffect } from "react";
import useRouter from '@hooks/useRouter';

// libs
import TagManager, { TagManagerArgs } from "react-gtm-module";

interface AnalyticsEvent {
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue?: string;
}

interface UseAnalyticsRouterOptions {
  gtmId: string;
  pageEvent?: AnalyticsEvent;
}

const useAnalyticsRouter = (options: UseAnalyticsRouterOptions): void => {
  const router = useRouter();

  useEffect(() => {
    // Initialize Google Tag Manager
    const { gtmId, pageEvent } = options;

    if (!gtmId) {
      console.warn('Google Tag Manager ID is not provided');
      return;
    }

    const tagManagerArgs: TagManagerArgs = {
      gtmId: gtmId,
    };

    TagManager.initialize(tagManagerArgs);

    // Track initial pageview
    const handleInitialPageview = (url: string) => {
      if (pageEvent) {
        TagManager.dataLayer({
          dataLayer: {
            page: url || window.location.pathname,
            ...pageEvent,
          },
        });
      } else {
        TagManager.dataLayer({
          dataLayer: {
            page: url || window.location.pathname,
          },
        });
      }
    };

    handleInitialPageview(router.pathname);

    // Track pageview on route change
    const handleRouteChange = (url: string) => {

      if (!gtmId) return;

      if (pageEvent) {
        TagManager.dataLayer({
          dataLayer: {
            page: url,
            ...pageEvent,
          },
        });
      } else {
        TagManager.dataLayer({
          dataLayer: {
            page: url,
          },
        });
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // Clean up event listener on unmount
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };

  }, [options, router.events]);
};

export default useAnalyticsRouter;
